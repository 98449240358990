import 'react-big-calendar/lib/css/react-big-calendar.css';
import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';

import { query } from '../../helpers/QueryHelper';
// import events from './Events';

const localizer = momentLocalizer(moment);

export default function TeacherPage() {
  const [events, setEvents] = React.useState([]);

  React.useEffect(() => {
    query('p_API_iOFFICE_CALENDAR_GLOBAL_EBM_GetEvents', {}, 'Office').then((results) => {
      // console.log(results);
      setEvents(results);
    });
  }, []);

  React.useEffect(() => {}, []);

  return (
    <div style={{ backgroundColor: 'white', padding: 24, minHeight: 1000 }}>
      <h1>Teacher Calendar</h1>
      <Calendar
        events={events}
        // views={allViews}
        step={60}
        showMultiDayTimes
        defaultDate={new Date()}
        localizer={localizer}
      />
    </div>
  );
}
