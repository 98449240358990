export default {
  HOME: '/',
  // LOGIN
  ACCOUNT_GOOGLE_LOGIN: '/account/google/login',
  ACCOUNT_LOGIN: '/account/login',

  DYNAMIC_REPORT: '/dynamic-report/:id',
  DYNAMIC_REPORT_SEARCH: '/dynamic-report-search',

  // TFS
  TFS_DASHBOARD: '/tfs/dashboard',

  // AWS
  ADMIN_AWS_SES_TEMPLATED_EMAILS: '/admin/aws/ses/templated-emails',

  // SMART EDUCATION
  SE_INTRO: '/se/intro',
  SE_TEACHER_REGISTER: '/se/teacher/register',
  SE_TEACHER_CREATE_CLASS: '/se/teacher/create-class',
  SE_TEACHER_ADD_STUDENTS_TO_CLASS: '/se/teacher/add-students-to-class',
  SE_TEACHER_VIEW_CLASS: '/se/teacher/view-class',
  SE_TEACHER_VIEW_SCORE: '/se/teacher/view-score',

  // STUDENT
  SE_STUDENT_REGISTER: '/se/student/register',
  SE_STUDENT_JOIN_CLASS: '/se/student/join-class',

  // EBM_TEACHER_SCHEDULE
  EBM_INDEX: '/ebm/',
  // EBM - Teacher Schedule
  EBM_TEACHER_SCHEDULE: '/ebm/teacher/schedule',

  EBM_TEACHER_ATTENDANCE: '/ebm/teacher/attendance/division/:divisionId/group/:group',

  // PARENT
  SE_PARENT_VIEW_SCORE: '/se/parent/view-score',

  MONITOR: '/administration/monitor',
  MONITOR_TABLESIZE: '/administration/monitor/table-size',

  LOG_WORKCHAT: '/log/workchat',

  // CALENDAR
  CALENDAR_TEACHER: '/calendar/teacher',
  CALENDAR_STUDENT: '/calendar/student',

  EDITOR: '/editor',
  REVENUES: '/ebm/revenues',
  REVENUES_MONTH: '/ebm/revenues/month',
  REVENUES_RACE: '/ebm/revenues/race',
  REVENUES_REALTIME: '/ebm/revenues/realtime',
  MYAMES_USERS: '/myames/users',
  HIGHTCHART: '/highchart',

  CREATE_DEVICE: '/ebm/ames-learning/4.0/create-device',
  CREATE_PRODUCT: '/ebm/ames-learning/4.0/create-product',
  CLASSES: '/ebm/ames-learning/4.0/classes',
  USERS: '/ebm/ames-learning/4.0/users',
  STUDENTS: '/ebm/ames-learning/4.0/students',
  IELTS_WORDS: '/resources/ielts/words',
};
