/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
import React, { Fragment } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { query } from 'helpers/QueryHelper';
import { Skeleton } from 'antd';
import AttendanceCard from './AttendanceCard';
import AssignmentSessions from './AssignmentSessions';

export default ({ divisionId, classId, onAfterAttendance }) => {
  // QUERY CLIENT
  const queryClient = useQueryClient();

  // QUERY
  const key = ['[p_EBM_ATTENDANCE_GetAttendancesByClassId]', classId];

  const { data, isLoading } = useQuery(key, () => {
    return query('[p_EBM_ATTENDANCE_GetAttendancesByClassId]', { classId }, 'EBM');
  });

  if (isLoading) {
    return <Skeleton paragraph={{ rows: 4 }} />;
  }

  return (
    <div>
      {data &&
        data.map((item) => {
          return (
            <Fragment key={`student-${item.studentId}`}>
              <AttendanceCard
                data={item}
                classId={classId}
                divisionId={divisionId}
                onAfterAttendance={() => {
                  queryClient.invalidateQueries(key);
                  if (onAfterAttendance && typeof onAfterAttendance === 'function') {
                    onAfterAttendance();
                  }
                }}
              />
              <AssignmentSessions classId={classId} studentId={item.studentId} />
            </Fragment>
          );
        })}
    </div>
  );
};
