/* eslint-disable react/prop-types */
import { CalendarOutlined, HomeOutlined, PhoneOutlined, StarOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Card, Space } from 'antd';
import colors from 'constants/colors';
import { execute } from 'helpers/QueryHelper';
import moment from 'moment';
import React from 'react';

function AttendanceCard({ data, classId, onAfterAttendance }) {
  const [attendance, setAttendance] = React.useState(data);

  // console.log(attendance);

  const onAttendance = (studentId, status) => {
    execute('p_EBM_ATTENDANCE_ChangeStatus', { studentId, classId, status }, 'EBM').then((result) => {
      setAttendance({ ...attendance, status });
      if (onAfterAttendance && typeof onAfterAttendance === 'function') {
        onAfterAttendance();
      }
    });
  };

  let statusColor = 'gray';
  switch (attendance.status) {
    case 'VANG':
      statusColor = 'red';
      break;
    case 'COMAT':
      statusColor = 'green';
      break;
    case 'NGHI':
      statusColor = 'orange';
      break;
    default:
      statusColor = 'gray';
      break;
  }

  return (
    <Card
      size='small'
      headStyle={{ backgroundColor: statusColor, color: 'white' }}
      title={
        <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span>{attendance.studentName}</span>
          <React.Fragment>
            {attendance.status === 'CHUADIEMDANH' && (
              <Space style={{ whiteSpace: 'nowrap' }}>
                <span>Chưa điểm danh</span>
              </Space>
            )}
            {attendance.status === 'VANG' && (
              <Space style={{ whiteSpace: 'nowrap' }}>
                <span>Vắng</span>
              </Space>
            )}
            {attendance.status === 'NGHI' && (
              <Space style={{ whiteSpace: 'nowrap' }}>
                <span>Xin nghỉ phép</span>
              </Space>
            )}
            {attendance.status === 'COMAT' && (
              <Space style={{ whiteSpace: 'nowrap' }}>
                <span>Có mặt</span>
              </Space>
            )}
          </React.Fragment>
        </Space>
      }
      style={{ marginBottom: 12, marginTop: 8 }}
      actions={[
        <Button
          size='small'
          icon={<StarOutlined key='VANG' style={{ color: 'red' }} />}
          key='VANG'
          type='link'
          onClick={() => {
            onAttendance(attendance.studentId, 'VANG');
          }}
        >
          <span>Vắng</span>
        </Button>,
        <Button
          size='small'
          icon={<StarOutlined key='COMAT' style={{ color: 'green' }} />}
          key='COMAT'
          type='link'
          onClick={() => {
            onAttendance(attendance.studentId, 'COMAT');
          }}
        >
          <span>Có mặt</span>
        </Button>,
        <Button
          size='small'
          icon={<StarOutlined key='NGHI' style={{ color: 'orange' }} />}
          key='NGHI'
          type='link'
          onClick={() => {
            onAttendance(attendance.studentId, 'NGHI');
          }}
        >
          <span>Phép</span>
        </Button>,

        <Button
          size='small'
          icon={<StarOutlined key='COMAT' style={{ color: 'grey' }} />}
          type='link'
          key='CHUADIEMDANH'
          onClick={() => {
            onAttendance(attendance.studentId, 'CHUADIEMDANH');
          }}
        >
          <span>Chưa</span>
        </Button>,
      ]}
    >
      <Card.Meta
        description={
          <Space direction='vertical'>
            <Space>
              <HomeOutlined style={{ color: colors.font }} />
              <span style={{ color: colors.font }}>{attendance.className}</span>
            </Space>
            <Space>
              <CalendarOutlined style={{ color: colors.font }} />
              {attendance.startDate &&
                <span style={{ color: colors.font }}>{moment(attendance.startDate).format('DD/MM/yyyy')}</span>
              }
              {attendance.finishDate &&
                <span style={{ color: colors.font }}>{moment(attendance.finishDate).format('DD/MM/yyyy')}</span>
              }
            </Space>
            <Space>
              <UserOutlined style={{ color: colors.font }} />
              <span style={{ color: colors.font }}>{attendance.studentId}</span>
            </Space>
            <Space>
              <PhoneOutlined style={{ color: colors.font }} />
              <span style={{ color: colors.font }}>{attendance.phoneNumber}</span>
            </Space>
          </Space>
        }
      />
    </Card>
  );
}

export default AttendanceCard;
