import React, { Component } from 'react';
import { Form, DatePicker, Row, Col, Input, Select, Button, Typography, Table, Divider, Card, Progress } from 'antd';

// HIGH CHARTS
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import moment from 'moment';
import numeral from 'numeral';
import _ from 'lodash';
import axios from 'axios';

import ReportHelper from '../helpers/ReportHelper';

const { Option } = Select;

const DropDownList = (props) => {
  const { getFieldDecorator } = props;
  return (
    <Form.Item label={props.label}>
      {getFieldDecorator(props.id, {
        initialValue: props.selectedValue ? props.selectedValue : props.items[0].value,
        rules: [{ required: false, message: 'Chưa nhập dữ liệu' }],
      })(
        <Select>
          {props.items.map((item, index) => (
            <Option key={index} value={item.value}>
              {item.name}
            </Option>
          ))}
        </Select>,
      )}
    </Form.Item>
  );
};

const months = [
  { value: 1, name: 'Tháng 1' },
  { value: 2, name: 'Tháng 2' },
  { value: 3, name: 'Tháng 3' },
  { value: 4, name: 'Tháng 4' },
  { value: 5, name: 'Tháng 5' },
  { value: 6, name: 'Tháng 6' },
  { value: 7, name: 'Tháng 7' },
  { value: 8, name: 'Tháng 8' },
  { value: 9, name: 'Tháng 9' },
  { value: 10, name: 'Tháng 10' },
  { value: 11, name: 'Tháng 11' },
  { value: 12, name: 'Tháng 12' },
];

const years = [
  { value: 2018, name: '2018' },
  { value: 2019, name: '2019' },
  { value: 2020, name: '2020' },
];

const columns = [
  {
    title: 'Đơn vị / Chi nhánh / Trung tâm',
    dataIndex: 'divisionName',
    key: 'divisionName',
    width: '50%',
    align: 'left',
    render: (text, record) => <div style={{ fontWeight: '700' }}>{`${_.toUpper(text)}`}</div>,
  },
  {
    title: 'Cả năm',
    children: [
      {
        title: () => (
          <span style={{ whiteSpace: 'nowrap' }} className='ant-table-column-title'>
            Kế hoạch
          </span>
        ),
        dataIndex: 'revenueTargetOfYear',
        key: 'revenueTargetOfYear',
        align: 'right',
        render: (text, record) => <div style={{ fontWeight: '400' }}>{`${numeral(text).format('0,000')}`}</div>,
        sorter: (a, b) => a.revenueTargetOfYear - b.revenueTargetOfYear,
      },
      {
        title: () => (
          <span style={{ whiteSpace: 'nowrap' }} className='ant-table-column-title'>
            Doanh thu
          </span>
        ),
        dataIndex: 'amountOfYear',
        key: 'amountOfYear',
        align: 'right',
        render: (text, record) => <div style={{ fontWeight: '400' }}>{`${numeral(text).format('0,000')}`}</div>,
        sorter: (a, b) => a.amountOfYear - b.amountOfYear,
      },
      {
        title: () => (
          <span style={{ whiteSpace: 'nowrap' }} className='ant-table-column-title'>
            Đạt (%)
          </span>
        ),
        dataIndex: 'performanceOfYear',
        key: 'performanceOfYear',
        align: 'center',
        render: (text, record) => (
          <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
            <Progress type='line' percent={text} showInfo={false} strokeWidth={14} strokeLinecap='round' status={text >= 100 ? 'success' : 'active'} />
            <div style={{ whiteSpace: 'nowrap', marginTop: 2, paddingLeft: 6, fontSize: 12 }}>{`${numeral(text).format('0')}%`}</div>
          </div>
        ),
        // render: (text, record) => <PercentBar percent={text} />,
        sorter: (a, b) => a.performanceOfYear - b.performanceOfYear,
      },
      {
        title: () => (
          <span style={{ whiteSpace: 'nowrap' }} className='ant-table-column-title'>
            Xếp hạng
          </span>
        ),
        dataIndex: 'rankOfYear',
        key: 'rankOfYear',
        align: 'right',
        render: (text, record) => (
          <div style={{ fontWeight: '400' }}>
            {text} / {record.numberOfDivisions}
          </div>
        ),
        sorter: (a, b) => a.rankOfYear - b.rankOfYear,
      },
    ],
  },
  {
    title: 'Tháng',
    children: [
      {
        title: () => (
          <span style={{ whiteSpace: 'nowrap' }} className='ant-table-column-title'>
            Kế hoạch
          </span>
        ),
        dataIndex: 'revenueTarget',
        key: 'revenueTarget',
        align: 'right',
        render: (text, record) => <div style={{ fontWeight: '400' }}>{`${numeral(text).format('0,000')}`}</div>,
        sorter: (a, b) => a.revenueTarget - b.revenueTarget,
      },
      {
        title: () => (
          <span style={{ whiteSpace: 'nowrap' }} className='ant-table-column-title'>
            Doanh thu
          </span>
        ),
        dataIndex: 'amount',
        key: 'amount',
        align: 'right',
        render: (text, record) => <div style={{ fontWeight: '400' }}>{`${numeral(text).format('0,000')}`}</div>,
        sorter: (a, b) => a.amount - b.amount,
      },
      {
        title: () => (
          <span style={{ whiteSpace: 'nowrap' }} className='ant-table-column-title'>
            Đạt (%)
          </span>
        ),
        dataIndex: 'performance',
        key: 'performance',
        align: 'center',
        render: (text, record) => (
          <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
            <Progress type='line' percent={text} showInfo={false} strokeWidth={14} strokeLinecap='round' status={text >= 100 ? 'success' : 'active'} />
            <div style={{ marginTop: 2, paddingLeft: 6, fontSize: 12 }}>{`${numeral(text).format('0')}%`}</div>
          </div>
        ),
        sorter: (a, b) => a.performance - b.performance,
      },
      {
        title: () => (
          <span style={{ whiteSpace: 'nowrap' }} className='ant-table-column-title'>
            Xếp hạng
          </span>
        ),
        dataIndex: 'rank',
        key: 'rank',
        align: 'right',
        render: (text, record) => (
          <div style={{ fontWeight: '400' }}>
            {text} / {record.numberOfDivisions}
          </div>
        ),
        sorter: (a, b) => a.rank - b.rank,
      },
    ],
  },
];

const highchartOptions = {
  chart: {
    type: 'bar',
  },
  title: {
    text: 'Báo cáo doanh thu',
  },
  subtitle: {
    text: 'Source: AMES ENGLISH',
  },
  credits: {
    enabled: false,
  },
  legend: {
    layout: 'vertical',
    align: 'right',
    verticalAlign: 'top',
    x: -40,
    y: 80,
    floating: true,
    borderWidth: 1,
    backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
    shadow: true,
  },
  xAxis: {
    categories: [],
    title: {
      text: null,
    },
  },
  yAxis: {
    min: 0,
    title: {
      text: 'Doanh thu (VNĐ)',
      align: 'high',
    },
    labels: {
      overflow: 'justify',
    },
  },
  tooltip: {
    valueSuffix: ' VNĐ',
  },
  plotOptions: {
    bar: {
      dataLabels: {
        enabled: true,
      },
    },
    series: {
      type: 'column',
      cursor: 'pointer',
      colorByPoint: true, // Multi colors
      showInLegend: false,
    },
  },
};

// ------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------
class RevenuesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      results: [],
      columns: columns,
      year: moment().year(),
      month: moment().month() + 1,
      highchartOptions: null,
    };
  }

  componentDidMount() {}

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      // console.log(values);
      const { month, year } = values;
      this.setState({ month, year });

      ReportHelper.Query('p_API_EBM_GetRevenues', {
        month,
        year,
      }).then((results) => {
        // console.log(results);
        // SET TABLE ATTRIBUTES
        const { columns } = this.state;
        columns[2].title = `Tháng ${this.state.month}`;

        const numberOfDivisions = results.length;
        const top1DivisionName = _.head(results).divisionName;
        const top1Amount = _.head(results).amount;
        const top10Amount = results[9].amount;

        results.forEach((item) => {
          item.top1DivisionName = top1DivisionName;
          item.numberOfDivisions = numberOfDivisions;
          item.rankText = item.rank === 1 ? 'Quán quân 🏆' : item.rank + ' / ' + numberOfDivisions;
          item.distanceToTop1Amount = top1Amount - item.amount;
          item.distanceToTop10Amount = top10Amount - item.amount;
          // console.log(item);
        });

        // Highc0harts: Options
        const series = [
          {
            name: 'Doanh thu',
            data: _.reduce(
              results,
              (accumulator, currentValue) => {
                accumulator.push(currentValue.amount);
                return accumulator;
              },
              [],
            ),
          },
        ];
        let categories = [];
        results.forEach((item) => {
          categories.push(item.divisionName);
        });

        highchartOptions.xAxis.categories = categories;
        highchartOptions.series = series;

        highchartOptions.chart.height = results.length * 30 + 120;
        console.log(series);

        this.setState({ results, columns, highchartOptions });
      });
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
    };

    return (
      <Card title='Báo cáo doanh thu theo tháng' bordered={false} style={{ width: '100%' }}>
        <Form {...formItemLayout} onSubmit={this.handleSubmit}>
          <DropDownList id='month' label='Tháng' selectedValue={moment().month() + 1} placeholder='' items={months} getFieldDecorator={getFieldDecorator} />
          <DropDownList id='year' label='Năm' selectedValue={moment().year()} placeholder='' items={years} getFieldDecorator={getFieldDecorator} />
          <Form.Item
            wrapperCol={{
              xs: { span: 24, offset: 0 },
              sm: { span: 18, offset: 6 },
            }}
          >
            <Button type='primary' htmlType='submit'>
              Tổng hợp
            </Button>
          </Form.Item>
        </Form>

        <div>
          <HighchartsReact allowChartUpdate={true} immutable={false} updateArgs={[true, true, true]} highcharts={Highcharts} options={this.state.highchartOptions} />
        </div>

        <Table columns={this.state.columns} rowKey='divisionId' dataSource={this.state.results} pagination={false} bordered />
      </Card>
    );
  }
}

const form = RevenuesPage;
export default form;
