/* eslint-disable no-unused-vars */
/* eslint-disable react/no-danger */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-eval */
/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';
import numeral from 'numeral';
import moment from 'moment';
import _ from 'lodash';
import { Button, Checkbox, Modal, Progress, Rate, Tag } from 'antd';

import { renderDetails } from './renderDetails';
import { renderForm_LMS_ChamDiemToeicSpeaking } from './LMS/renderForm_LMS_ChamDiemToeicSpeaking';
import { renderForm_LMS_ChamDiemToeicWriting } from './LMS/renderForm_LMS_ChamDiemToeicWriting';

import { renderForm_EBM_DanhGiaCuocGoi } from './EBM/renderForm_EBM_DanhGiaCuocGoi';
import { renderForm_EBM_DanhGiaCheckIn } from './EBM/renderForm_EBM_DanhGiaCheckIn';

import siteSettings from 'configs/siteSettings';  

function truncate(str, n) {
  if (str) return str.length > n ? `${str.substr(0, n - 1)} ...` : str;
  return '';
}

// const IFrameModal = ({ url }) => {
//   const [visible, setVisible] = React.useState(false);

//   return (
//     <React.Fragment>
//       <div
//         style={{ width: '100%', fontWeight: '400', whiteSpace: 'nowrap', cursor: 'pointer', color: '#1890ff' }}
//         onClick={() => {
//           setVisible(true);
//         }}
//       >
//         Xem
//       </div>
//       <Modal
//         title='Thông tin chi tiết'
//         centered
//         visible={visible}
//         onCancel={() => {
//           setVisible(false);
//         }}
//       >
//         <iframe height='100%' width='100%' src={url} frameBorder='0' title='Xem kết quả' />
//       </Modal>
//     </React.Fragment>
//   );
// };

const LongTextModal = ({ text }) => {
  const [visible, setVisible] = React.useState(false);

  return (
    <React.Fragment>
      <Button
        type='link'
        size='small'
        onClick={() => {
          setVisible(true);
        }}
      >
        {_.upperFirst(truncate(text, 26))}
      </Button>

      <Modal
        title='Thông tin chi tiết'
        centered
        visible={visible}
        onCancel={() => {
          setVisible(false);
        }}
      >
        <p>{_.upperFirst(text)}</p>
      </Modal>
    </React.Fragment>
  );
};

const renderCheckbox = (formatString, suffixString, formatConditions, style) => (text, record, index) => {
  return <Checkbox checked={text} />;
};

// --------------------------------------------------------------------------------------------------------------------
const renderNumber = (formatString, suffixString, formatConditions, style) => (text, record, index) => {
  let number = 0;
  if (text) number = text;
  let format = '0,0';
  let suffix = '';

  if (formatString) format = formatString;
  if (suffixString) suffix = suffixString;

  let formatStyle = {};
  let formatClassName = '';
  if (formatConditions) {
    for (let i = 0; i < formatConditions.length; i++) {
      const condition = eval(formatConditions[i].condition);
      if (condition) {
        formatStyle = formatConditions[i].style;
        formatClassName = formatConditions[i].className;
      }
    }
  }

  return {
    props: {
      className: formatClassName,
      style: { minWidth: 72, whiteSpace: 'nowrap', textAlign: 'right', ...style, ...formatStyle },
    },
    children: <span>{`${text === null || text === undefined ? '' : numeral(number).format(format)}${suffix}`}</span>,
  };
};
// --------------------------------------------------------------------------------------------------------------------
const renderMoney = (formatString, suffixString, formatConditions, style) => (text, record, index) => {
  let number = 0;
  if (text) number = text;
  let format = '0,0';
  let suffix = ' đ';

  if (formatString) format = formatString;
  if (suffixString) suffix = suffixString;

  let formatStyle = {};
  let formatClassName = '';
  if (formatConditions) {
    for (let i = 0; i < formatConditions.length; i++) {
      const condition = eval(formatConditions[i].condition);
      if (condition) {
        formatStyle = formatConditions[i].style;
        formatClassName = formatConditions[i].className;
      }
    }
  }

  return {
    props: {
      className: formatClassName,
      style: { minWidth: 96, whiteSpace: 'nowrap', textAlign: 'right', ...style, ...formatStyle },
    },
    children: <span>{`${text === null || text === undefined ? '' : numeral(number).format(format)}${suffix}`}</span>,
  };
};
// --------------------------------------------------------------------------------------------------------------------
const renderProgress = (formatString, suffixString, formatConditions, style) => (text, record, index) => {
  let number = 0;
  if (text) number = text;
  let format = '0,0';
  let suffix = '%';

  if (formatString) format = formatString;
  if (suffixString) suffix = suffixString;

  let formatStyle = {};
  let formatClassName = '';
  if (formatConditions) {
    for (let i = 0; i < formatConditions.length; i++) {
      const condition = eval(formatConditions[i].condition);
      if (condition) {
        formatStyle = formatConditions[i].style;
        formatClassName = formatConditions[i].className;
      }
    }
  }

  return {
    props: {
      className: formatClassName,
      style: { ...style, ...formatStyle },
    },
    children: (
      <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
        <Progress type='line' percent={text} showInfo={false} strokeWidth={14} strokeLinecap='round' status={number >= 80 ? 'success' : text >= 50 ? '' : 'exception'} style={{ width: 75 }} />
        <div style={{ whiteSpace: 'nowrap', marginTop: 3, paddingLeft: 6, fontSize: 12 }}>{`${numeral(number).format(format)}${suffix}`}</div>
      </div>
    ),
  };
};
// --------------------------------------------------------------------------------------------------------------------
const renderPercent = (formatString, suffixString, formatConditions, style) => (text, record, index) => {
  let number = 0;
  if (text) number = text;
  let format = '0,0';
  let suffix = '%';

  if (formatString) format = formatString;
  if (suffixString) suffix = suffixString;

  let formatStyle = {};
  let formatClassName = '';
  if (formatConditions) {
    for (let i = 0; i < formatConditions.length; i++) {
      const condition = eval(formatConditions[i].condition);
      if (condition) {
        formatStyle = formatConditions[i].style;
        formatClassName = formatConditions[i].className;
      }
    }
  }

  return {
    props: {
      className: formatClassName,
      style: { minWidth: 72, whiteSpace: 'nowrap', textAlign: 'right', ...style, ...formatStyle },
    },
    children: <span>{`${text === null || text === undefined ? '' : numeral(number).format(format)}${suffix}`}</span>,
  };
};
// --------------------------------------------------------------------------------------------------------------------
const renderUpperCase = (formatString, suffixString, formatConditions, style) => (text, record, index) => {
  let content = '';
  if (text) content = text.toUpperCase();

  let format = '';
  let suffix = '';
  if (formatString) format = formatString;
  if (suffixString) suffix = suffixString;

  let formatStyle = {};
  let formatClassName = '';
  if (formatConditions) {
    for (let i = 0; i < formatConditions.length; i++) {
      const condition = eval(formatConditions[i].condition);
      if (condition) {
        formatStyle = formatConditions[i].style;
        formatClassName = formatConditions[i].className;
      }
    }
  }

  return {
    props: {
      className: formatClassName,
      style: { ...style, ...formatStyle },
    },
    children: <div style={{ whiteSpace: 'nowrap' }}>{`${content}${suffix}`}</div>,
  };
};
// --------------------------------------------------------------------------------------------------------------------
const renderLowerCase = (formatString, suffixString, formatConditions, style) => (text, record, index) => {
  let content = '';
  if (text) content = text.toLowerCase();

  let format = '';
  let suffix = '';
  if (formatString) format = formatString;
  if (suffixString) suffix = suffixString;

  let formatStyle = {};
  let formatClassName = '';
  if (formatConditions) {
    for (let i = 0; i < formatConditions.length; i++) {
      const condition = eval(formatConditions[i].condition);
      if (condition) {
        formatStyle = formatConditions[i].style;
        formatClassName = formatConditions[i].className;
      }
    }
  }

  return {
    props: {
      className: formatClassName,
      style: { ...style, ...formatStyle },
    },
    children: <div style={{ whiteSpace: 'nowrap' }}>{`${content}${suffix}`}</div>,
  };
};
// --------------------------------------------------------------------------------------------------------------------
const renderDate = (formatString, suffixString, formatConditions, style) => (text, record, index) => {
  if (!text) return <span />;

  let date = '';
  if (text) date = text;
  let format = 'DD/MM/YYYY';
  let suffix = '';

  if (formatString) format = formatString;
  if (suffixString) suffix = suffixString;

  let formatStyle = {};
  let formatClassName = '';
  if (formatConditions) {
    for (let i = 0; i < formatConditions.length; i++) {
      const condition = eval(formatConditions[i].condition);
      if (condition) {
        formatStyle = formatConditions[i].style;
        formatClassName = formatConditions[i].className;
      }
    }
  }

  return {
    props: {
      className: formatClassName,
      style: { ...style, ...formatStyle },
    },
    children: <div style={{ whiteSpace: 'nowrap' }}>{`${moment(date).format(format)}${suffix}`}</div>,
  };
};
// --------------------------------------------------------------------------------------------------------------------
const renderTime = (formatString, suffixString, formatConditions, style) => (text, record, index) => {
  if (!text) return <span />;

  let date = '';
  if (text) date = text;
  let format = 'HH:mm';
  let suffix = '';

  if (formatString) format = formatString;
  if (suffixString) suffix = suffixString;

  let formatStyle = {};
  let formatClassName = '';
  if (formatConditions) {
    for (let i = 0; i < formatConditions.length; i++) {
      const condition = eval(formatConditions[i].condition);
      if (condition) {
        formatStyle = formatConditions[i].style;
        formatClassName = formatConditions[i].className;
      }
    }
  }

  return {
    props: {
      className: formatClassName,
      style: { ...style, ...formatStyle },
    },
    children: <div style={{ whiteSpace: 'nowrap' }}>{`${moment(date).format(format)}${suffix}`}</div>,
  };
};
// --------------------------------------------------------------------------------------------------------------------
const renderDateTime = (formatString, suffixString, formatConditions, style) => (text, record, index) => {
  if (!text) return <span />;

  let date = '';
  if (text) date = text;
  let format = 'DD/MM/YYYY HH:mm';
  let suffix = '';

  if (formatString) format = formatString;
  if (suffixString) suffix = suffixString;

  let formatStyle = {};
  let formatClassName = '';
  if (formatConditions) {
    for (let i = 0; i < formatConditions.length; i++) {
      const condition = eval(formatConditions[i].condition);
      if (condition) {
        formatStyle = formatConditions[i].style;
        formatClassName = formatConditions[i].className;
      }
    }
  }

  return {
    props: {
      className: formatClassName,
      style: { ...style, ...formatStyle },
    },
    children: <div style={{ whiteSpace: 'nowrap' }}>{`${moment(date).format(format)}${suffix}`}</div>,
  };
};
// --------------------------------------------------------------------------------------------------------------------
const renderText = (formatString, suffixString, formatConditions, style) => (text, record, index) => {
  if (!text) return <span />;

  let content = '';
  if (text) content = text;
  let format = '';
  let suffix = '';

  if (formatString) format = formatString;
  if (suffixString) suffix = suffixString;

  let formatStyle = {};
  let formatClassName = '';
  if (formatConditions) {
    for (let i = 0; i < formatConditions.length; i++) {
      const condition = eval(formatConditions[i].condition);
      if (condition) {
        formatStyle = formatConditions[i].style;
        formatClassName = formatConditions[i].className;
      }
    }
  }

  return {
    props: {
      className: formatClassName,
      style: { ...style, ...formatStyle },
    },
    children: <span>{`${content}${suffix}`}</span>,
  };
};
// --------------------------------------------------------------------------------------------------------------------
const renderNoWrap = (formatString, suffixString, formatConditions, style) => (text, record, index) => {
  if (!text) return <span />;

  let content = '';
  if (text) content = text;
  let format = '';
  let suffix = '';

  if (formatString) format = formatString;
  if (suffixString) suffix = suffixString;

  let formatStyle = {};
  let formatClassName = '';
  if (formatConditions) {
    for (let i = 0; i < formatConditions.length; i++) {
      const condition = eval(formatConditions[i].condition);
      if (condition) {
        formatStyle = formatConditions[i].style;
        formatClassName = formatConditions[i].className;
      }
    }
  }

  return {
    props: {
      className: formatClassName,
      style: { minWidth: 96, whiteSpace: 'nowrap', ...style, ...formatStyle },
    },
    children: <div style={{ whiteSpace: 'nowrap' }}>{`${content}${suffix}`}</div>,
  };
};
// --------------------------------------------------------------------------------------------------------------------
const renderRate = (formatString, suffixString, formatConditions, style) => (text, record, index) => {
  return <Rate style={style} allowHalf defaultValue={text} />;
};

const renderAudio = (formatString, suffixString, formatConditions, style) => (text, record, index) => {
  return (
    <audio controls>
      <source src={text} type='audio/mpeg' />
    </audio>
  );
};

// --------------------------------------------------------------------------------------------------------------------
const renderRateDisabled = (formatString, suffixString, formatConditions, style) => (text, record, index) => {
  return <Rate style={style} allowHalf disabled defaultValue={text} />;
};
// --------------------------------------------------------------------------------------------------------------------
const renderHtml = (formatString, suffixString, formatConditions, style) => (text, record, index) => {
  return <div style={style} dangerouslySetInnerHTML={{ __html: text }} />;
};
// --------------------------------------------------------------------------------------------------------------------
const renderClassStatus = (formatString, suffixString, formatConditions, style) => (text, record, index) => {
  if (text) {
    let color = '#1890ff';
    switch (text) {
      case 'Not Ready':
        color = '#ff8f00';
        break;
      case 'Ready':
        color = '#1890ff';
        break;
      case 'Finish':
        color = '#00c853';
        break;
      default:
        color = '#1890ff';
    }

    return (
      <Tag color={color} style={{ width: '100%', textAlign: 'center', ...style }}>
        {text}
      </Tag>
    );
  }
  return <span />;
};
// --------------------------------------------------------------------------------------------------------------------
const renderLongText = (formatString, suffixString, formatConditions, style) => (text, record, index) => {
  return <LongTextModal text={text} />;
};
// --------------------------------------------------------------------------------------------------------------------
// renderComplex
const renderWithStyle = (formatString, suffixString, formatConditions, style) => (text, record, index) => {
  return <div style={style}>{text}</div>;
};
// --------------------------------------------------------------------------------------------------------------------
const renderPdf = (formatString, suffixString, formatConditions, style) => (text, record, index) => {
  let url = `https://docs.google.com/viewer?url=${siteSettings.domain_url_ames}/toeiconline/pt/result/${text}`;
  if (record.url) {
    url = record.url;
  }
  return (
    <div style={{ textAlign: 'center', ...style }}>
      <a href={url} target='_blank' style={{ fontWeight: '700' }} title='Xem thông tin chi tiết' rel='noreferrer'>
        Pdf
      </a>
    </div>
  );
};
// --------------------------------------------------------------------------------------------------------------------
const render_EBM_StudentDetails = (formatString, suffixString, formatConditions, style) => (text, record, index) => {
  const url = `${siteSettings.domain_url_ebm}/screen/StudentDetail.aspx?StudentId=${record.studentId}`;
  return (
    <div style={{ textAlign: 'center', ...style }}>
      <a href={url} target='_blank' style={{ fontWeight: '700' }} title='Xem thông tin chi tiết' rel='noreferrer'>
        {text}
      </a>
    </div>
  );

  // let url = `https://softech.edu.vn/screen/ClassEdit.aspx?tab=student&ClassId=${record.classId}`;
  // return <IFrameModal url={url} text={text} />;
};
// --------------------------------------------------------------------------------------------------------------------
const render_EBM_ClassDetails = (formatString, suffixString, formatConditions, style) => (text, record, index) => {
  const url = `${siteSettings.domain_url_ebm}/screen/ClassEdit.aspx?tab=student&ClassId=${record.classId}`;
  return (
    <div style={{ textAlign: 'center', ...style }}>
      <a href={url} target='_blank' style={{ fontWeight: '700' }} title='Xem thông tin chi tiết' rel='noreferrer'>
        {text}
      </a>
    </div>
  );

  // let url = `https://softech.edu.vn/screen/ClassEdit.aspx?tab=student&ClassId=${record.classId}`;
  // return <IFrameModal url={url} text={text} />;
};
// --------------------------------------------------------------------------------------------------------------------
// renderComplex
const renderExpression = (expression, formatString, suffixString, formatConditions, style) => (text, record, index) => {
  let content = '';
  if (text) content = eval(expression);
  let format = '0,0';
  let suffix = '';

  if (formatString) format = formatString;
  if (suffixString) suffix = suffixString;

  let formatStyle = {};
  let formatClassName = '';
  if (formatConditions) {
    for (let i = 0; i < formatConditions.length; i++) {
      const condition = eval(formatConditions[i].condition);
      if (condition) {
        formatStyle = formatConditions[i].style;
        formatClassName = formatConditions[i].className;
      }
    }
  }

  return {
    props: {
      className: formatClassName,
      style: { ...style, ...formatStyle },
    },
    children: <div style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>{`${numeral(content).format(format)}${suffix}`}</div>,
  };
};
// --------------------------------------------------------------------------------------------------------------------
// renderFormatConditions
/*
"render": "renderFormatCondition",
"formatConditions": [
  {
    "condition": "record.successfulPercent_MedianSucessfulPercentOfSystem === 'Cao hơn'",
    "style": {
      "backgroundColor": "yellow"
    }
  },
  {
    "condition": "record.successfulPercent_MedianSucessfulPercentOfSystem === 'Thấp hơn'",
    "style": {
      "backgroundColor": "gray"
    }
  }
],
*/
const renderFormatCondition = (formatString, suffixString, formatConditions, style) => (text, record, index) => {
  let formatStyle = {};
  let formatClassName = '';
  if (formatConditions) {
    for (let i = 0; i < formatConditions.length; i++) {
      const condition = eval(formatConditions[i].condition);
      if (condition) {
        formatStyle = formatConditions[i].style;
        formatClassName = formatConditions[i].className;
      }
    }
  }

  return {
    props: {
      className: formatClassName,
      style: { ...style, ...formatStyle },
    },
    children: (
      <div style={style}>
        {text}
        {suffixString}
      </div>
    ),
  };
};

const renderLink = (linkText, linkExpression, linkCondition, formatConditions, style) => (text, record, index) => {
  let formatStyle = {};
  let formatClassName = '';
  if (formatConditions) {
    for (let i = 0; i < formatConditions.length; i++) {
      const condition = eval(formatConditions[i].condition);
      if (condition) {
        formatStyle = formatConditions[i].style;
        formatClassName = formatConditions[i].className;
      }
    }
  }

  return {
    props: {
      className: formatClassName,
      style: { ...style, ...formatStyle },
    },
    children: (
      <React.Fragment>
        {eval(linkCondition) ? (
          <Link to={eval(linkExpression)}>
            <Button type='link' size='small'>
              {linkText}
            </Button>
          </Link>
        ) : (
          <React.Fragment />
        )}
      </React.Fragment>
    ),
  };
};
// --------------------------------------------------------------------------------------------------------------------
// --------------------------------------------------------------------------------------------------------------------
// --------------------------------------------------------------------------------------------------------------------
const renderFunctions = {
  renderText,
  renderCheckbox,
  renderNumber,
  renderMoney,
  renderDate,
  renderTime,
  renderDateTime,
  renderPercent,
  renderProgress,
  renderNoWrap,

  // RATE
  renderRate,
  renderRateDisabled,

  // LOWER / UPPER
  renderUpperCase,
  renderLowerCase,

  // HTML
  renderHtml,

  // LONG TEXT WITH MODAL
  renderLongText,

  // STYLE
  renderWithStyle,

  // DETAILS
  renderDetails,

  // LINK
  renderLink,

  // PDF
  renderPdf,
  // EBM
  renderClassStatus,
  render_EBM_ClassDetails,
  render_EBM_StudentDetails,

  // EXPRESSION
  renderExpression,
  renderFormatCondition,
  // LMS
  renderForm_LMS_ChamDiemToeicSpeaking,
  renderForm_LMS_ChamDiemToeicWriting,

  // EBM
  renderForm_EBM_DanhGiaCuocGoi,
  renderForm_EBM_DanhGiaCheckIn,

  renderAudio,
};

export default renderFunctions;
