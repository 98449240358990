import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { Layout } from 'antd';

// Routes
import routes from '../constants/routes';
// Pages
import LoginPage from '../pages/Account/LoginPage';
// import GoogleLoginPage from '../pages/Account/GoogleLoginPage';

const { Content } = Layout;

class PublicLayout extends Component {
  render() {
    return (
      <div style={{ width: '100%', margin: '0 auto' }}>
        <Layout style={{ display: 'flex', flex: 1, height: '100vh', backgroundColor: 'white' }}>
          <Content style={{ paddingTop: 12, display: 'flex', flex: 1, flexDirection: 'column' }}>
            {/* <Route exact path={routes.ACCOUNT_LOGIN} component={LoginPage} /> */}
            <Route exact path={routes.ACCOUNT_LOGIN} component={LoginPage} />
          </Content>
        </Layout>
      </div>
    );
  }
}

export default PublicLayout;
