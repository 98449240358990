export default {
  storedProcedure: 'STORED NAME HERE',
  formName: 'Tìm theo học viên',
  appName: 'EBM',
  requiredMark: false,
  submitButtonText: 'Tìm kiếm',
  formFields: [
    {
      label: 'StudentId / Name / Phone:',
      name: 'searchValue',
      // initialValue: '',
      hasFeedback: true,
      rules: [
        {
          required: true,
          message: 'Chưa nhập mã học viên',
        },
      ],
      inputComponent: {
        type: 'Input',
        props: {
          // style: { width: 150 },
        },
      },
    },
    {
      label: 'Có lịch trong ngày',
      name: 'checkAttendance',
      initialValue: true,
      inputComponent: {
        type: 'Checkbox',
        props: {},
      },
    },
  ],
};
