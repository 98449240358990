import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyCPqeJ_Tckj0cqWvcIFw_2otvNm_Zm5Gms',
  authDomain: 'reports.softech.cloud',
  databaseURL: 'https://softech-reports.firebaseio.com',
  projectId: 'softech-reports',
  storageBucket: 'softech-reports.appspot.com',
  messagingSenderId: '280385626149',
  appId: '1:280385626149:web:5a0fb2e9ca002b049ddfcc',
  measurementId: 'G-1Z48M51RPS',
};

firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const firestore = firebase.firestore();
