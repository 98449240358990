import React from 'react';
import { Route } from 'react-router-dom';
import { Layout } from 'antd';

// Components
import Attendance from 'pages/EBM/Teacher/Attendance';
import routes from '../constants/routes';

const { Content } = Layout;

function NoneAuthenticatedLayout() {
  return (
    <React.Fragment>
      <Layout style={{ minHeight: '100vh' }}>
        <Layout>
          <Content>
            <Route exact path={routes.EBM_TEACHER_ATTENDANCE} component={Attendance} />
          </Content>
        </Layout>
      </Layout>
    </React.Fragment>
  );
}

export default NoneAuthenticatedLayout;
