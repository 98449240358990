import { combineReducers } from 'redux';
import auth from './auth/reducers';
import report from './report/reducers';
// import schoolbooksReducer from './schoolbooksModule/reducers';

// COMBINE MANY REDUCERS
const rootReducer = combineReducers({
  auth,
  report,
});

export default rootReducer;
