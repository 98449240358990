import React, { Component } from 'react';
import Login from '../../modules/auth/components/Login';

export default class LoginPage extends Component {
  render() {
    return (
      <div>
        <Login />
      </div>
    );
  }
}
