/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import moment from 'moment';
import { Input, Button, Card, Form, Table, Spin, DatePicker, Select, Modal, Popconfirm, message } from 'antd';
import { FileAddOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { layout, tailLayout } from 'packages/DynamicForm/formLayouts';

import { getTemplatedEmail, listTemplatedEmails, createTemplatedEmail, deleteTemplatedEmail, sendTemplatedEmail, updateTemplatedEmail } from './Services';

const { Option, OptGroup } = Select;

export default function TemplatedEmail() {
  const [form] = Form.useForm();
  const [detailForm] = Form.useForm();
  const [updateForm] = Form.useForm();

  const [refresh, setRefresh] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState([]);
  const [modalVisible, setModalVisible] = React.useState(false);
  const [modalUpdateVisible, setModalUpdateVisible] = React.useState(false);
  const [currentTemplatedEmail, setCurrentTemplatedEmail] = React.useState(null);

  const columns = [
    {
      title: () => <span style={{ whiteSpace: 'nowrap' }}>#</span>,
      width: '1%',
      dataIndex: 'no',
      key: 'no',
      align: 'right',
      render: (text, record, index) => <div style={{ whiteSpace: 'nowrap', fontWeight: '400' }}>{index + 1}</div>,
    },

    {
      title: () => <span style={{ whiteSpace: 'nowrap' }}>Template Name</span>,
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      render: (text, record) => <div style={{ whiteSpace: 'nowrap', fontWeight: '400' }}>{text}</div>,
    },
    {
      title: () => <span style={{ whiteSpace: 'nowrap' }}>Created Time</span>,
      width: '1%',
      dataIndex: 'createdTimestamp',
      key: 'createdTimestamp',
      align: 'center',
      render: (text, record) => <div style={{ whiteSpace: 'nowrap', fontWeight: '400' }}>{`${moment(text).format('MM/DD/yyyy hh:mm A')}`}</div>,
    },
    {
      width: '1%',
      dataIndex: 'details',
      key: 'details',
      align: 'center',
      render: (text, record) => (
        <Button
          type='default'
          size='small'
          onClick={() => {
            getTemplatedEmail(record.name).then((value) => {
              setCurrentTemplatedEmail({ ...value.result.template });
              setModalVisible(true);
            });
          }}
        >
          Detail
        </Button>
      ),
    },

    {
      width: '1%',
      dataIndex: 'action-update',
      key: 'action-update',
      align: 'center',
      render: (text, record) => (
        <Button
          type='primary'
          size='small'
          onClick={() => {
            getTemplatedEmail(record.name).then((value) => {
              setCurrentTemplatedEmail({ ...value.result.template });
              setModalUpdateVisible(true);
            });
          }}
        >
          Edit
        </Button>
      ),
    },

    {
      width: '1%',
      dataIndex: 'delete',
      key: 'delete',
      align: 'center',
      render: (text, record) => (
        <Popconfirm
          title='Are you sure to delete this template?'
          onConfirm={() => {
            deleteTemplatedEmail(record.name).then((value) => {
              setRefresh(refresh + 1);
            });
          }}
          onCancel={() => {}}
          okText='Yes'
          cancelText='No'
        >
          <Button type='danger' size='small'>
            Delete
          </Button>
        </Popconfirm>
      ),
    },
  ];

  React.useEffect(() => {
    if (modalVisible) {
      detailForm.resetFields();
    }
  }, [detailForm, modalVisible]);

  React.useEffect(() => {
    if (modalUpdateVisible) {
      updateForm.resetFields();
    }
  }, [updateForm, modalUpdateVisible]);

  React.useEffect(() => {
    listTemplatedEmails()
      .then((value) => {
        setData(value.result.templatesMetadata);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, [refresh]);

  return (
    <div>
      <Card title='SES' bordered={false} style={{ width: '100%' }}>
        <Form
          {...layout}
          form={form}
          name='aws-ses-add-templated-email'
          initialValues={{ date: moment(), messageType: 'bm-callouts-7days' }}
          requiredMark
          onFinish={(values) => {
            createTemplatedEmail(values)
              .then((value) => {
                setRefresh(refresh + 1);
              })
              .catch((err) => console.error(err));
          }}
        >
          <Form.Item
            key='templateName'
            label={<span style={{ fontWeight: '700', color: 'rgba(0, 0, 0, 0.75)' }}>Template Name</span>}
            name='templateName'
            rules={[
              {
                required: true,
                message: "'Template Name' is required",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            key='subjectPart'
            label={<span style={{ fontWeight: '700', color: 'rgba(0, 0, 0, 0.75)' }}>Subject Part</span>}
            name='subjectPart'
            rules={[
              {
                required: true,
                message: "'Subject Part' is required",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            key='textPart'
            label={<span style={{ fontWeight: '700', color: 'rgba(0, 0, 0, 0.75)' }}>Text Part</span>}
            name='textPart'
            rules={[
              {
                required: true,
                message: "'Text Part' is required",
              },
            ]}
          >
            <Input.TextArea autoSize={{ minRows: 3, maxRows: 100 }} />
          </Form.Item>

          <Form.Item
            key='htmlPart'
            label={<span style={{ fontWeight: '700', color: 'rgba(0, 0, 0, 0.75)' }}>Html Part</span>}
            name='htmlPart'
            rules={[
              {
                required: true,
                message: "'Html Part' is required",
              },
            ]}
          >
            <Input.TextArea autoSize={{ minRows: 5, maxRows: 100 }} />
          </Form.Item>

          {/* Submit */}
          <Form.Item {...tailLayout}>
            <Button icon={<FileAddOutlined />} type='primary' htmlType='submit'>
              Create template
            </Button>
          </Form.Item>
        </Form>
      </Card>
      <div style={{ marginTop: 24 }}>{!loading && <Table columns={columns} rowKey='name' dataSource={data} pagination={false} bordered size='small' />}</div>
      <Modal
        title='Templated Email - Details'
        centered
        visible={modalVisible}
        onCancel={() => {
          setModalVisible(false);
        }}
        footer={null}
        width={800}
      >
        {currentTemplatedEmail && (
          <React.Fragment>
            <Form
              {...layout}
              form={detailForm}
              name='aws-ses-send-templated-email'
              initialValues={{ ...currentTemplatedEmail, senderAddress: 'AMES ENGLISH <mailer@ames.edu.vn>', templateData: '{ "name": "Tony Woo" }' }}
              requiredMark
              onFinish={(values) => {
                sendTemplatedEmail({
                  receiverAddress: values.receiverAddress,
                  senderAddress: values.senderAddress,
                  templateName: values.templateName,
                  templateData: JSON.parse(values.templateData),
                })
                  .then((value) => {
                    message.success('Send test mail: Success');
                  })
                  .catch((err) => console.error(err));
              }}
            >
              <Form.Item
                key='templateName'
                label={<span style={{ fontWeight: '700', color: 'rgba(0, 0, 0, 0.75)' }}>Template Name</span>}
                name='templateName'
                rules={[
                  {
                    required: true,
                    message: "'Template Name' is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                key='subjectPart'
                label={<span style={{ fontWeight: '700', color: 'rgba(0, 0, 0, 0.75)' }}>Subject Part</span>}
                name='subjectPart'
                rules={[
                  {
                    required: true,
                    message: "'Subject Part' is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                key='textPart'
                label={<span style={{ fontWeight: '700', color: 'rgba(0, 0, 0, 0.75)' }}>Text Part</span>}
                name='textPart'
                rules={[
                  {
                    required: true,
                    message: "'Text Part' is required",
                  },
                ]}
              >
                <Input.TextArea autoSize={{ minRows: 3, maxRows: 100 }} />
              </Form.Item>

              <Form.Item
                key='htmlPart'
                label={<span style={{ fontWeight: '700', color: 'rgba(0, 0, 0, 0.75)' }}>Html Part</span>}
                name='htmlPart'
                rules={[
                  {
                    required: true,
                    message: "'Html Part' is required",
                  },
                ]}
              >
                <Input.TextArea autoSize={{ minRows: 5, maxRows: 100 }} />
              </Form.Item>

              <Form.Item
                key='senderAddress'
                label={<span style={{ fontWeight: '700', color: 'rgba(0, 0, 0, 0.75)' }}>Sender Address</span>}
                name='senderAddress'
                rules={[
                  {
                    required: true,
                    message: "'Sender Address' is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                key='receiverAddress'
                label={<span style={{ fontWeight: '700', color: 'rgba(0, 0, 0, 0.75)' }}>Receiver Address</span>}
                name='receiverAddress'
                rules={[
                  {
                    required: true,
                    message: "'Receiver Address' is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                key='templateData'
                label={<span style={{ fontWeight: '700', color: 'rgba(0, 0, 0, 0.75)' }}>Template Data</span>}
                name='templateData'
                rules={[
                  {
                    required: true,
                    message: "'Template Data' is required",
                  },
                ]}
              >
                <Input.TextArea autoSize={{ minRows: 5, maxRows: 100 }} />
              </Form.Item>

              {/* Submit */}
              <Form.Item {...tailLayout}>
                <Button icon={<FileAddOutlined />} type='primary' htmlType='submit'>
                  Send test email
                </Button>
              </Form.Item>
            </Form>
          </React.Fragment>
        )}
      </Modal>

      <Modal
        title='Templated Email - Update'
        centered
        visible={modalUpdateVisible}
        onOk={() => {
          updateForm.submit();
        }}
        onCancel={() => {
          setModalUpdateVisible(false);
        }}
        width={800}
        okText='Save'
      >
        {currentTemplatedEmail && (
          <React.Fragment>
            <Form
              {...layout}
              form={updateForm}
              name='aws-ses-update-templated-email'
              initialValues={{ ...currentTemplatedEmail }}
              requiredMark
              onFinish={(values) => {
                updateTemplatedEmail(values)
                  .then((value) => {
                    setModalUpdateVisible(false);
                    setRefresh(refresh + 1);
                  })
                  .catch((err) => console.error(err));
              }}
            >
              <Form.Item
                key='templateName'
                label={<span style={{ fontWeight: '700', color: 'rgba(0, 0, 0, 0.75)' }}>Template Name</span>}
                name='templateName'
                rules={[
                  {
                    required: true,
                    message: "'Template Name' is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                key='subjectPart'
                label={<span style={{ fontWeight: '700', color: 'rgba(0, 0, 0, 0.75)' }}>Subject Part</span>}
                name='subjectPart'
                rules={[
                  {
                    required: true,
                    message: "'Subject Part' is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                key='textPart'
                label={<span style={{ fontWeight: '700', color: 'rgba(0, 0, 0, 0.75)' }}>Text Part</span>}
                name='textPart'
                rules={[
                  {
                    required: true,
                    message: "'Text Part' is required",
                  },
                ]}
              >
                <Input.TextArea autoSize={{ minRows: 3, maxRows: 100 }} />
              </Form.Item>

              <Form.Item
                key='htmlPart'
                label={<span style={{ fontWeight: '700', color: 'rgba(0, 0, 0, 0.75)' }}>Html Part</span>}
                name='htmlPart'
                rules={[
                  {
                    required: true,
                    message: "'Html Part' is required",
                  },
                ]}
              >
                <Input.TextArea autoSize={{ minRows: 5, maxRows: 100 }} />
              </Form.Item>
            </Form>
          </React.Fragment>
        )}
      </Modal>
    </div>
  );
}
