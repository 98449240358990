import React, { Component } from 'react';
import { Form, DatePicker, Row, Col, Input, Select, Button, Typography, Table, Divider, Checkbox, Progress, Spin } from 'antd';

// HIGH CHARTS
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import moment from 'moment';
import numeral from 'numeral';
import _ from 'lodash';
import axios from 'axios';

import ReportHelper from '../helpers/ReportHelper';

const columns = [
  {
    title: 'Đơn vị / Chi nhánh / Trung tâm',
    dataIndex: 'divisionName',
    key: 'divisionName',
    width: '50%',
    align: 'left',
    render: (text, record) => <div style={{ fontWeight: '700' }}>{`${_.toUpper(text)}`}</div>,
  },
  {
    title: () => (
      <span style={{ whiteSpace: 'nowrap' }} className='ant-table-column-title'>
        Người dùng MyAMES
      </span>
    ),
    dataIndex: 'totalOfMyAmesUsers',
    key: 'totalOfMyAmesUsers',
    align: 'right',
    render: (text, record) => <div style={{ fontWeight: '400' }}>{`${numeral(text).format('0,000')}`}</div>,
    sorter: (a, b) => a.totalOfMyAmesUsers - b.totalOfMyAmesUsers,
  },
  {
    title: () => (
      <span style={{ whiteSpace: 'nowrap' }} className='ant-table-column-title'>
        Học viên đang học
      </span>
    ),
    dataIndex: 'totalOfStudents',
    key: 'totalOfStudents',
    align: 'right',
    render: (text, record) => <div style={{ fontWeight: '400' }}>{`${numeral(text).format('0,000')}`}</div>,
    sorter: (a, b) => a.totalOfStudents - b.totalOfStudents,
  },
  {
    title: () => (
      <span style={{ whiteSpace: 'nowrap' }} className='ant-table-column-title'>
        Đạt (%)
      </span>
    ),
    dataIndex: 'performance',
    key: 'performance',
    align: 'center',
    render: (text, record) => (
      <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
        <Progress type='line' percent={text} showInfo={false} strokeWidth={14} strokeLinecap='round' status={text >= 100 ? 'success' : 'active'} />
        <div style={{ whiteSpace: 'nowrap', marginTop: 2, paddingLeft: 6, fontSize: 12 }}>{`${numeral(text).format('0')}%`}</div>
      </div>
    ),
    // render: (text, record) => <PercentBar percent={text} />,
    sorter: (a, b) => a.performance - b.performance,
  },
  {
    title: () => (
      <span style={{ whiteSpace: 'nowrap' }} className='ant-table-column-title'>
        Xếp hạng
      </span>
    ),
    dataIndex: 'rank',
    key: 'rank',
    align: 'right',
    render: (text, record) => <div style={{ fontWeight: '400' }}>{text}</div>,
    sorter: (a, b) => a.rank - b.rank,
  },
];

// ------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------
class Page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      result: [],
    };
  }

  componentDidMount() {
    ReportHelper.QueryFirst('p_API_MYAMES_GetActiveUsers', {})
      .then((result) => {
        this.setState({ result, loading: false });
        console.log(result);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    return (
      <div>
        {this.state.loading === false && (
          <div>
            <h5>Số liệu cập nhật lúc: {moment(this.state.result.executedDate).format('DD/MM/YYYY HH:mm')}</h5>
            <Table columns={columns} rowKey='divisionId' dataSource={this.state.result.jsonResults} pagination={false} bordered />
          </div>
        )}
        {this.state.loading && (
          <div style={{ textAlign: 'center' }}>
            <Spin />
          </div>
        )}
      </div>
    );
  }
}

const form = Page;
export default form;
