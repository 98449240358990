import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'antd';

import ReportHelper from 'helpers/ReportHelper';
import renderFunctions from 'packages/DynamicForm/renderFunctions';

const displayColumns = [
  {
    title: '#',
    key: 'index',
    width: '1%',
    align: 'right',
    render: (text, record, index) => <span style={{ fontWeight: '400' }}>{index + 1}</span>,
  },
  {
    title: 'Name',
    dataIndex: 'formName',
    key: 'formName',
    width: null,
    render: renderFunctions.renderWithStyle({ fontWeight: '700' }),
  },
  {
    title: 'Code',
    dataIndex: 'formCode',
    key: 'formCode',
    width: null,
    render: renderFunctions.renderNoWrap({}),
  },
  {
    title: 'Stored Procedure',
    dataIndex: 'storedProcedure',
    key: 'storedProcedure',
    width: null,
    render: renderFunctions.renderNoWrap({}),
  },
  {
    title: 'Creator',
    dataIndex: 'creator',
    key: 'creator',
    width: null,
    render: renderFunctions.renderNoWrap({}),
  },
  {
    title: '#',
    key: 'index',
    width: '1%',
    align: 'right',
    // eslint-disable-next-line no-underscore-dangle
    render: (text, record, index) => <Link to={`dynamic-report/${record._id}`}>Xem</Link>,
  },
];

export default function SearchPage() {
  const [loading, setLoading] = React.useState(true);
  const [forms, setForms] = React.useState([]);

  const getForms = () => {
    ReportHelper.GetForms()
      .then((result) => {
        setForms(result);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  React.useEffect(getForms, []);

  return (
    <React.Fragment>
      <div style={{ marginTop: 24 }}>
        <Table rowKey='id' size='small' dataSource={forms} columns={displayColumns} bordered loading={loading} pagination={false} />
      </div>
    </React.Fragment>
  );
}
