/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import moment from 'moment';
import { Button, Card, Form, Table, Spin, DatePicker, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { gql, useQuery, useLazyQuery } from '@apollo/client';
import { layout, tailLayout } from 'packages/DynamicForm/formLayouts';

const { Option, OptGroup } = Select;

const GET_LOGS = gql`
  query getWorkChatLogs($messageType: String!, $fromDate: DateTime!, $toDate: DateTime!) {
    workChatLogs(sort: "date:desc", where: { messageType: $messageType, date_gte: $fromDate, date_lte: $toDate }) {
      workChatId
      date
      messageText
      messageType
      messageData
    }
  }
`;

const columns = [
  {
    title: () => <span style={{ whiteSpace: 'nowrap' }}>#</span>,
    dataIndex: 'no',
    key: 'no',
    align: 'right',
    render: (text, record, index) => <div style={{ whiteSpace: 'nowrap', fontWeight: '400' }}>{index + 1}</div>,
  },

  {
    title: () => <span style={{ whiteSpace: 'nowrap' }}>Time</span>,
    dataIndex: 'date',
    key: 'date',
    align: 'center',
    render: (text, record) => <div style={{ whiteSpace: 'nowrap', fontWeight: '400' }}>{`${moment(text).format('hh:mm A')}`}</div>,
  },
  {
    title: () => <span style={{ whiteSpace: 'nowrap' }}>Division</span>,
    dataIndex: 'date',
    key: 'date',
    align: 'left',
    render: (text, record) => <span style={{ whiteSpace: 'nowrap', fontWeight: '700' }}>{`${record.messageData.divisionName}`}</span>,
    sorter: (a, b) => {
      return a.messageData.divisionName.localeCompare(b.messageData.divisionName);
    },
  },
  {
    title: () => <span style={{ whiteSpace: 'nowrap' }}>Recipient</span>,
    dataIndex: 'division',
    key: 'division',
    align: 'left',
    render: (text, record) => (
      <div style={{ whiteSpace: 'nowrap', fontWeight: '400' }}>
        {record.messageData && (
          <React.Fragment>
            <div>
              <a target='_blank' rel='noreferrer' href={`https://anhnguames.workplace.com/profile.php?id=${record.messageData.bm && record.messageData.bm.workplaceId}`}>{`BM: ${record.messageData.bm && record.messageData.bm.fullName}`}</a>
            </div>
            <div>
              <a target='_blank' rel='noreferrer' href={`https://anhnguames.workplace.com/profile.php?id=${record.messageData.sao && record.messageData.sao.workplaceId}`}>
                {`SAO: ${record.messageData.sao && record.messageData.sao.fullName}`}
              </a>
            </div>
            {record.messageData.fullName && (
              <div>
                <a target='_blank' rel='noreferrer' href={`https://anhnguames.workplace.com/profile.php?id=${record.messageData.workplaceId}`}>{`AO: ${record.messageData.fullName}`}</a>
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    ),
  },

  {
    title: () => <span style={{ whiteSpace: 'nowrap' }}>Message Type</span>,
    dataIndex: 'messageType',
    key: 'messageType',
    align: 'left',
    render: (text, record) => <div style={{ whiteSpace: 'nowrap', fontWeight: '700' }}>{`${text}`}</div>,
  },

  {
    title: () => <span style={{ whiteSpace: 'nowrap' }}>Message</span>,
    dataIndex: 'messageText',
    key: 'messageText',
    align: 'left',
    width: '100%',
  },
];

function WorkChatLogPage() {
  const [form] = Form.useForm();
  const [getLogs, { loading, data, error }] = useLazyQuery(GET_LOGS);

  return (
    <div>
      <Card title='Workchat Logs' bordered={false} style={{ width: '100%' }}>
        <Form
          form={form}
          {...layout}
          name='dynamic-report-form'
          initialValues={{ date: moment(), messageType: 'bm-callouts-7days' }}
          requiredMark
          onFinish={(values) => {
            getLogs({
              variables: {
                fromDate: values.date.format('YYYY-MM-DDT00:00:00.000Z'),
                toDate: values.date.format('YYYY-MM-DDT23:59:59.999Z'),
                messageType: values.messageType,
              },
            });
          }}
        >
          <Form.Item key='date' label={<span style={{ fontWeight: '700', color: 'rgba(0, 0, 0, 0.75)' }}>Ngày</span>} name='date' rules={[]}>
            <DatePicker format='DD/MM/YYYY' />
          </Form.Item>

          <Form.Item key='messageType' label={<span style={{ fontWeight: '700', color: 'rgba(0, 0, 0, 0.75)' }}>Nhóm nhận thông báo</span>} name='messageType' rules={[]}>
            <Select style={{ width: '100%' }} virtual={false}>
              <OptGroup label='THỐNG KÊ CUỘC GỌI'>
                <Option value='bm-callouts-7days'>BM: Thống kê cuộc gọi trong 7 ngày</Option>
                <Option value='sao-callouts-7days'>SAO: Thống kê cuộc gọi trong 7 ngày</Option>
                <Option value='ao-callouts-7days'>AO: Thống kê cuộc gọi trong 7 ngày</Option>
                <Option value='bm-callouts-daily'>BM: Thống kê cuộc gọi hằng ngày</Option>
                <Option value='sao-callouts-daily'>SAO: Thống kê cuộc gọi hằng ngày</Option>
                <Option value='ao-callouts-daily'>AO: Thống kê cuộc gọi hằng ngày</Option>
              </OptGroup>
              <OptGroup label='THỐNG KÊ ĐIỂM DANH'>
                <Option value='bm-attendances-daily'>BM: Thống kê điểm danh hằng ngày</Option>
              </OptGroup>
              <OptGroup label='THỐNG KÊ DOANH THU'>
                <Option value='bm-revenues-callouts-daily'>BM: Thống kê CUỘC GỌI + CHECKIN, HỌC THỬ + DOANH THU hằng ngày</Option>
                <Option value='bm-invoice-no-submit-daily'>BM: Thống kê doanh thu chưa xác nhận hằng ngày</Option>
                <Option value='sao-invoice-no-submit-daily'>SAO: Thống kê doanh thu chưa xác nhận hằng ngày</Option>
              </OptGroup>
            </Select>
          </Form.Item>

          {/* Submit */}
          <Form.Item {...tailLayout}>
            <Button icon={<SearchOutlined />} type='primary' htmlType='submit'>
              Xem
            </Button>
          </Form.Item>
        </Form>
      </Card>
      <div style={{ marginTop: 24 }}>{!loading && <Table columns={columns} rowKey='date' dataSource={data && data.workChatLogs} pagination={false} bordered size='small' />}</div>
    </div>
  );
}

export default WorkChatLogPage;
