/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { Card } from 'antd';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import numeral from 'numeral';
import { query, queryFirst } from 'helpers/QueryHelper';

export default function Memory() {
  const [pieChartOptions, setPieChartOptions] = React.useState(null);

  React.useEffect(() => {
    queryFirst('p_API_SYSTEM_GetMemorySizes', {}, 'Default').then((data) => {
      console.log(data);

      const options = {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie',
          // height: 400,
        },
        credits: {
          enabled: true,
        },
        title: {
          text: null,
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
        },
        plotOptions: {
          pie: {
            // allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: false,
              format: '<b>{point.name}</b>: {point.percentage:.1f} %',
            },
            showInLegend: true,
          },
        },
        series: [
          {
            name: 'MB',
            data: [
              {
                name: `Free (${numeral(data.available_Physical_Memory_In_MB / 1024).format('0,000.00')} GB)`,
                y: (data.available_Physical_Memory_In_MB / data.total_Physical_Memory_In_MB) * 100,
                sliced: true,
                selected: true,
                color: '#a0d911',
              },
              {
                name: `Used (${numeral((data.total_Physical_Memory_In_MB - data.available_Physical_Memory_In_MB) / 1024).format('0,000.00')} GB)`,
                y: 100 - (data.available_Physical_Memory_In_MB / data.total_Physical_Memory_In_MB) * 100,
                color: '#2c3e50',
              },
            ],
          },
        ],
      };

      setPieChartOptions(options);
    });
  }, []);

  return (
    <Card title='Trạng thái bộ nhớ' bordered={false} style={{ width: '100%' }}>
      {pieChartOptions && <HighchartsReact allowChartUpdate immutable={false} updateArgs={[true, true, true]} highcharts={Highcharts} options={pieChartOptions} />}
    </Card>
  );
}
