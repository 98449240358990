import { query } from 'helpers/QueryHelper';
import { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

type CurriculumnsProps = {
  id: number;
  name: string;
  curriculum: { id: number; name: string }[];
};

const useGettingCurriculumAll = () => {
  const KEY = ['p_API_MYAMES_GetCurriculum_ALL'];
  const { data, isLoading } = useQuery<CurriculumnsProps[]>(KEY, () => query('p_API_MYAMES_GetCurriculum_ALL', {}, 'SmartEducation'));
  const [dataLevel1, setDataLevel1] = useState<Omit<CurriculumnsProps, 'curriculum'>[]>([]);
  const [_dataLevel2, setDataLevel2] = useState<Pick<CurriculumnsProps, 'curriculum'>[]>([]);
  const [dataLevel2Index, setDataLevel2Index] = useState<number>();

  useEffect(() => {
    if (!isLoading) {
      const curriculumsLevel1 = data.map(({ id, name }) => ({ id, name }));
      setDataLevel1(curriculumsLevel1);
      const curriculumsLevel2 = data.map(({ curriculum }) => ({ curriculum }));
      setDataLevel2(curriculumsLevel2);
    }
  }, [data, isLoading]);

  /**
   * Dữ liệu cấp 2 (chính là curriculum trong CurriculumnProps).
   * @param index Vị trí trong mảng
   * @returns Mảng dữ liệu cấp 2
   */
  const dataLevel2 = useCallback(() => {
    return _dataLevel2[dataLevel2Index]?.curriculum ?? [];
  }, [_dataLevel2, dataLevel2Index]);

  return { dataLevel1, dataLevel2, isLoading, setDataLevel2Index };
};

export default useGettingCurriculumAll;
