/* eslint-disable react/prop-types */

import './index.css';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
// import { FormattedMessage, setLocale, getLocale } from 'umi/locale';
import { Menu, Dropdown, Avatar, Badge } from 'antd';

import { UserOutlined, SettingOutlined } from '@ant-design/icons';

// import NoticeIcon from '../NoticeIcon';
// import HeaderSearch from '../HeaderSearch';

import { login, setLoggedInUser } from '../../modules/auth/actions';

import { auth } from '../../firebase';

// import LanguageSwitch from '../../modules/schoolbooksModule/components/LanguageSwitch';

class RightContent extends PureComponent {
  render() {
    const { loggedInUser, setLoggedInUser, onMenuClick } = this.props;

    const menu = (
      <Menu theme='light' selectedKeys={[]} onClick={onMenuClick}>
        <Menu.Item key='userCenter'>
          <UserOutlined />
          Account center
          {/* <FormattedMessage id="menu.account.center" defaultMessage="account center" /> */}
        </Menu.Item>
        <Menu.Item key='userinfo'>
          <SettingOutlined />
          Account settings
          {/* <FormattedMessage id="menu.account.settings" defaultMessage="account settings" /> */}
        </Menu.Item>
        <Menu.Item key='triggerError'>
          {/* <Icon type='close-circle' /> */}
          Trigger Error
          {/* <FormattedMessage id="menu.account.trigger" defaultMessage="Trigger Error" /> */}
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item
          key='logout'
          onClick={() => {
            console.log('Log out');
            auth.signOut().then(() => {
              localStorage.removeItem('loggedInUser');
              setLoggedInUser(null);
            });
          }}
        >
          Log out
        </Menu.Item>
      </Menu>
    );

    const className = 'right';

    return (
      <React.Fragment>
        {loggedInUser && (
          <div className={className} style={{ backgroundColor: '#001529' }}>
            {/* <span className='action noticeButton'>
              <Badge count={10} style={{ boxShadow: 'none' }} className='badge' />
            </span> */}
            <Dropdown overlay={menu}>
              <span className='action account'>
                <Avatar size='small' className='avatar' src={loggedInUser.photoURL} alt='avatar' />
                {/* <span className='accountName' style={{ color: 'white' }}>
                  {loggedInUser.displayName}
                </span> */}
              </span>
            </Dropdown>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedInUser: state.auth.loggedInUser,
});

const mapDispatchToProps = (dispatch) => ({
  login: (username, password) => dispatch(login(username, password)),
  setLoggedInUser: (user) => dispatch(setLoggedInUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RightContent);
