import axios from 'axios';
import host from 'constants/host';

const axiosClient = axios.create({
  baseURL: `${host.baseUrl}/api/v1/aws/ses`,
  headers: {
    'Content-Type': 'application/json',
    Authorization: 'Basic 12C1F7EF9AC8E288FBC2177B7F54D',
    ApplicationName: 'SmartEducation',
  },
});

function createTemplatedEmail(values) {
  return new Promise((resolve, reject) => {
    axiosClient
      .post('/templates/create', values)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function updateTemplatedEmail(values) {
  return new Promise((resolve, reject) => {
    axiosClient
      .post('/templates/update', values)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function getTemplatedEmail(templateName) {
  return new Promise((resolve, reject) => {
    axiosClient
      .post('/templates/get', { templateName })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function listTemplatedEmails() {
  return new Promise((resolve, reject) => {
    axiosClient
      .post('/templates/list', {})
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function deleteTemplatedEmail(templateName) {
  return new Promise((resolve, reject) => {
    axiosClient
      .post('/templates/delete', {
        templateName,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function sendTemplatedEmail(values) {
  return new Promise((resolve, reject) => {
    axiosClient
      .post('/send-templated-email', values)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export { getTemplatedEmail, listTemplatedEmails, createTemplatedEmail, deleteTemplatedEmail, sendTemplatedEmail, updateTemplatedEmail };
