import React, { Component } from 'react';
import { Row, Col } from 'antd';

import { query } from 'helpers/QueryHelper';
import Drive from './Drive';
import Memory from './Memory';

export default class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      driveC: null,
      driveD: null,
    };
  }

  componentDidMount() {
    query('p_API_SYSTEM_GetDriveSizes', {}, 'Default').then((result) => {
      const driveC = result[0];
      const driveD = result[1];

      this.setState({ driveC, driveD });
    });
  }

  render() {
    const { driveC, driveD } = this.state;
    return (
      <React.Fragment>
        <Row gutter={16}>
          <Col md={8} xs={24} style={{ marginBottom: 16 }}>
            <Memory />
          </Col>
          <Col md={8} xs={24} style={{ marginBottom: 16 }}>
            {driveC && <Drive title='Drive C' data={driveC} />}
          </Col>
          <Col md={8} xs={24} style={{ marginBottom: 16 }}>
            {driveD && <Drive title='Drive D' data={driveD} />}
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
