/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import _ from 'lodash';
import { useQuery, useQueryClient } from 'react-query';
import { query, queryFirst } from 'helpers/QueryHelper';
import { Button, Card, Tabs, Modal, Radio, Space, List, Divider, Input, Form } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import DynamicForm from 'components/DynamicComponents/DynamicForm/components/DynamicForm';
import { useHistory } from 'react-router';
import formStudent from './formStudent';
import AttendanceCard from './components/AttendanceCard';
import formClass from './formClass';
import ClassDetails from './components/ClassDetails';
import ClassCard from './components/ClassCard';
// import ClassSessions from './components/ClassSessions';
import AssignmentSessions from './components/AssignmentSessions';

export default ({ match }) => {
  const history = useHistory();
  const { divisionId, classId, group } = match.params;

  // QUERY CLIENT
  const queryClient = useQueryClient();

  // QUERY
  const keyGetClassesByDivisionId = [('[p_EBM_ATTENDANCE_GetClassesByDivisionId]', divisionId, group)];
  const { data, isLoading } = useQuery(keyGetClassesByDivisionId, () => {
    return query('[p_EBM_ATTENDANCE_GetClassesByDivisionId]', { divisionId, group }, 'EBM');
  });

  // console.log(data);
  const keyGetDivision = ['p_Division_Load', divisionId];
  const { data: division } = useQuery(keyGetDivision, () => {
    return queryFirst('dbo.p_Division_Load', { id: divisionId, entityColumn: 'id, divisionName' }, 'EBM');
  });

  const [loading, setLoading] = React.useState(false);
  const [students, setStudents] = React.useState(null);
  const [classes, setClasses] = React.useState(null);

  const [selectedTab, setSelectedTab] = React.useState('Default');
  const [selectedClass, setSelectedClass] = React.useState(null);

  // const [studentParameters, setStudentParameters] = React.useState({ searchValue: '', divisionId, checkAttendance: true, classId });
  // const [classParameters, setClassParameters] = React.useState({ searchValue: '', divisionId, classId });

  const [form] = Form.useForm();
  const [searchClassesInDay, setSearchClassesInDay] = React.useState();

  let searchStudentsForm = null;
  let searchClassesForm = null;

  // if (isLoading) {
  //   return (
  //     <div style={{ height: 120, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
  //       <Spin />
  //     </div>
  //   );
  // }

  return (
    <React.Fragment>
      <Card title={division?.divisionName?.toUpperCase()}>
        <Tabs
          defaultActiveKey='Default'
          onChange={(activeKey) => {
            setSelectedTab(activeKey);
          }}
        >
          <Tabs.TabPane tab='Các lớp trong ngày' key='Default'>
            <Space style={{ whiteSpace: 'nowrap' }}>
              <Radio.Group
                size='small'
                value={group?.toLowerCase()}
                onChange={(e) => {
                  history.push(`/ebm/teacher/attendance/division/${divisionId}/group/${e.target.value}`);
                  setSearchClassesInDay();
                  form.setFieldsValue({ searchClassesInDay: '' });
                }}
              >
                <Radio.Button value='acss'>ACSS</Radio.Button>
                <Radio.Button value='aekt'>AEKT</Radio.Button>
                <Radio.Button value='aee'>AEE</Radio.Button>
              </Radio.Group>

              <Input
                size='small'
                allowClear
                placeholder='Lọc tên lớp'
                onChange={(e) => {
                  const newData = data.filter((x) => x.className.toLowerCase().includes(e.target.value.toLowerCase()));
                  setSearchClassesInDay(newData);
                }}
              />
            </Space>
            <Divider />
            <List
              loading={isLoading}
              itemLayout='horizontal'
              dataSource={searchClassesInDay ?? data}
              renderItem={(item) => {
                return (
                  <ClassCard
                    item={item}
                    onClick={() => {
                      setSelectedClass(item);
                    }}
                  />
                );
              }}
            />
          </Tabs.TabPane>

          <Tabs.TabPane tab='Tìm học viên' key='SeachByStudents'>
            <DynamicForm
              loading={loading}
              layout='vertical'
              // initialValues={studentParameters}
              getFormInstance={(formInstance) => {
                searchStudentsForm = formInstance;
              }}
              initialFormData={formStudent}
              onSubmit={async (values) => {
                setLoading(true);
                // setStudentParameters({ ...values });
                const { searchValue, checkAttendance } = values;
                const result = await query('p_EBM_ATTENDANCE_SearchStudents', { searchValue, divisionId, status: checkAttendance }, 'EBM');
                setStudents([...result]);
                setLoading(false);
              }}
            />
            <div>
              {students &&
                students.map((item, index) => {
                  return (
                    <Fragment key={`student-${index}`}>
                      <AttendanceCard
                        data={item}
                        classId={item.classId}
                        onAfterAttendance={() => {
                          searchStudentsForm.submit();
                        }}
                      />
                      {/* <ClassSessions classId={item.classId} studentId={item.studentId} /> */}
                      <AssignmentSessions classId={item.classId} studentId={item.studentId} />
                    </Fragment>
                  );
                })}
            </div>
          </Tabs.TabPane>

          <Tabs.TabPane tab='Tìm lớp' key='SearchByClasses'>
            <DynamicForm
              loading={loading}
              layout='vertical'
              // initialValues={classParameters}
              initialFormData={formClass}
              getFormInstance={(formInstance) => {
                searchClassesForm = formInstance;
              }}
              onSubmit={async (values) => {
                setLoading(true);
                // setClassParameters({ ...values });
                const result = await query('p_EBM_ATTENDANCE_SearchClasses', { divisionId, searchValue: values.searchValue }, 'EBM');
                setClasses([...result]);
                setLoading(false);
              }}
            />
            {/* <div>
              <DynamicTable loading={false} dataSource={classes} initialTableData={columnsClass} onReload={undefined} extraColumns={extraColumns} />
            </div> */}

            {classes && (
              <List
                loading={loading}
                itemLayout='horizontal'
                dataSource={classes}
                renderItem={(item) => {
                  return (
                    <ClassCard
                      item={item}
                      onClick={() => {
                        setSelectedClass(item);
                      }}
                    />
                  );
                }}
              />
            )}
          </Tabs.TabPane>
        </Tabs>
      </Card>
      <Modal
        width='95%'
        bodyStyle={{ paddingInline: 10, paddingBlock: 2, paddingBlockEnd: 10, backgroundColor: '#F0F2F5' }}
        centered
        visible={selectedClass}
        title={`${selectedClass?.id}: ${selectedClass?.className}`}
        onCancel={() => {
          setSelectedClass(null);
        }}
        footer={null}
      >
        <ClassDetails
          divisionId={divisionId}
          classId={selectedClass?.id}
          onAfterAttendance={() => {
            if (selectedTab === 'Default') {
              queryClient.invalidateQueries(keyGetClassesByDivisionId);
            } else if (selectedTab === 'SearchByClasses') {
              searchClassesForm.submit();
            }
          }}
        />
      </Modal>
    </React.Fragment>
  );
};
