/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { Button, Card, Modal, Row, Col, Form, Input, InputNumber, Select, Space, notification } from 'antd';

import Loading from 'components/Loading';
import ReportHelper from 'helpers/ReportHelper';
import { execute, query } from 'helpers/QueryHelper';
import { useQuery } from 'react-query';

const getReportDetails = (id) => {
  return query('dbo.p_API_EBM_Report_CallsLog_Get', { id }, 'Report');
};

const DetailsModal = ({ record }) => {
  const loggedInUser = useSelector((state) => state.auth.loggedInUser);

  const [form] = Form.useForm();

  const [visible, setVisible] = React.useState(false);
  const [selectedRecordId, setSelectedRecordId] = React.useState(null);
  const [textAreaHeights, setTextAreaHeight] = React.useState({
    content: 0,
    goodPoints: 0,
    notGoodPoints: 0,
    shouldBeBetter: 0,
  });
  const [totalTextAreaHeight, setTotalTextAreaHeight] = React.useState(0);
  const openModal = (recordId) => {
    setVisible(true);
    setSelectedRecordId(recordId);
  };

  const KEY_GET_REPORT_DETAILS = ['p_API_EBM_Report_CallsLog_Get', selectedRecordId];
  const { data: report } = useQuery(KEY_GET_REPORT_DETAILS, () => getReportDetails(selectedRecordId), { enabled: !!selectedRecordId });

  React.useEffect(() => {
    form.setFieldsValue({
      content: report?.[0]?.callLogContent,
      // criteriaCode: report?.[0]?.criteriaId?.split(','),
      goodPoints: report?.[0]?.goodPoints,
      notGoodPoints: report?.[0]?.notGoodPoints,
      shouldBeBetter: report?.[0]?.shouldBeBetter,
      aoName: report?.[0]?.aoName,
      callDate: report?.[0]?.callDate,
      divisionName: report?.[0]?.divisionName,
      phone: report?.[0]?.phone,
    });
  }, [form, report]);

  const handleChangeTextarea = (name) => (e) => {
    setTextAreaHeight((prev) => ({
      ...prev,
      [name]: e.height,
    }));
  };

  React.useEffect(() => {
    const heightArray = Object.values(textAreaHeights);
    const totalHeight = heightArray.reduce((sum, height) => sum + height, 0);
    setTotalTextAreaHeight(totalHeight);
  }, [textAreaHeights]);

  return (
    <React.Fragment>
      {record?.id && (
        <div style={{ width: '100%', fontWeight: '700', whiteSpace: 'nowrap', cursor: 'pointer', color: '#1890ff' }} onClick={() => openModal(record?.id)}>
          Đánh giá
        </div>
      )}
      <Modal
        centered
        width='80vw'
        title='Đánh giá chất lượng cuộc gọi'
        visible={visible}
        // onCancel={() => {
        //   setVisible(false);
        // }}
        onCancel={() => setVisible(false)}
        bodyStyle={{ height: `calc(52vh + ${totalTextAreaHeight}px)` }}
        footer={[
          <Button
            type='primary'
            key='submit'
            onClick={() => {
              form.submit();
            }}
          >
            Lưu thông tin
          </Button>,
          <Button
            type='default'
            key='close-modal'
            onClick={() => {
              setVisible(false);
            }}
          >
            Đóng
          </Button>,
        ]}
      >
        <div style={{ display: 'flex', flexDirection: 'column', maxHeight: '75vh' }}>
          <div style={{ paddingTop: 12 }}>
            <Form
              labelCol={{
                span: 6,
              }}
              wrapperCol={{
                span: 18,
              }}
              form={form}
              name='basic'
              initialValues={{}}
              onFinish={(values) => {
                // console.log(values);

                const parameters = {
                  id: record.id,
                  criteriaCode: '',
                  goodPoints: values.goodPoints,
                  notGoodPoints: values.notGoodPoints,
                  shouldBeBetter: values.shouldBeBetter,
                  createdBy: loggedInUser.email,
                };

                // console.log(parameters);

                execute('p_API_EBM_Report_CallsLog_Save', parameters, 'Report')
                  .then((data) => {
                    setVisible(false);
                    notification.success({
                      message: 'Cập nhập đánh giá cuộc gọi thành công',
                    });
                  })
                  .catch((error) => {
                    alert('Có lỗi khi lưu thông tin. Liên hệ AMES - IT để được hỗ trợ');
                  });
              }}
              // onFinishFailed={(onFinishFailed)}
            >
              <Row gutter={[24, 24]}>
                <Col lg={12}>
                  <Form.Item label='Chi nhánh/Trung tâm' name='divisionName'>
                    <Input disabled style={{ color: 'black' }} />
                  </Form.Item>
                </Col>
                <Col lg={12}>
                  <Form.Item label='Tên A0' name='aoName'>
                    <Input disabled style={{ color: 'black' }} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[24, 24]}>
                <Col lg={12}>
                  <Form.Item label='Số máy gọi' name='phone'>
                    <Input disabled style={{ color: 'black' }} />
                  </Form.Item>
                </Col>
                <Col lg={12}>
                  <Form.Item label='Thời gian gọi' name='callDate'>
                    <Input disabled style={{ color: 'black' }} />
                  </Form.Item>
                </Col>
              </Row>
              <hr style={{ marginBlock: '1rem' }} />

              <Form.Item name='content' label='Nội dung cuộc gọi'>
                <Input.TextArea rows={4} name='content' onResize={handleChangeTextarea('content')} disabled style={{}} />
              </Form.Item>
              <Form.Item label='Âm thanh ghi âm'>
                <Space style={{ display: 'flex' }}>
                  <audio controls>
                    <source src={report?.[0]?.recordingFile} type='audio/mpeg' />
                  </audio>
                  <a href={report?.[0]?.recordingFile} target='_blank'>
                    Mở file ghi âm trong tab mới{' '}
                  </a>
                </Space>
              </Form.Item>
              <Form.Item label='Các tiêu chí đánh giá' name='criteriaCode'>
                {/* <Select
                  mode='multiple'
                  placeholder='Chọn tiêu chí đánh giá'
                  style={{ width: '100%' }}
                  options={[
                    { value: '1', label: 'Giọng nói' },
                    { value: '2', label: 'Đã biết đặt câu hỏi chưa?' },
                    { value: '3', label: 'Đã biết chú ý nghe và khai thác thông tin khách hàng chưa?' },
                    { value: '4', label: 'Đã có tính thuyết phục chưa?' },
                    { value: '5', label: 'Đã hướng đến khách hàng là trung tâm chưa? Nói về lợi ích của họ, giải quyết vấn đề của họ chứ không phải của mình chưa?' },
                    { value: '99', label: 'Khác' },
                  ]}
                /> */}
                <ul style={{ listStyleType: 'circle', paddingLeft: '1rem', paddingTop: '0.4rem' }}>
                  <li>Giọng nói</li>
                  <li>Đã biết đặt câu hỏi chưa?</li>
                  <li>Đã biết chú ý nghe và khai thác thông tin khách hàng chưa?</li>
                  <li>Đã có tính thuyết phục chưa?</li>
                  <li>Đã hướng đến khách hàng là trung tâm chưa? Nói về lợi ích của họ, giải quyết vấn đề của họ chứ không phải của mình chưa?</li>
                </ul>
              </Form.Item>
              <Form.Item name='goodPoints' label='Nhận xét các điểm tốt'>
                <Input.TextArea rows={4} name='goodPoints' onResize={handleChangeTextarea('goodPoints')} placeholder='Nhập nhận xét các điểm tốt' style={{}} />
              </Form.Item>
              <Form.Item name='notGoodPoints' label='Nhận xét các điểm chưa tốt'>
                <Input.TextArea rows={4} name='notGoodPoints' onResize={handleChangeTextarea('notGoodPoints')} placeholder='Nhập nhận xét các điểm chưa tốt' style={{}} />
              </Form.Item>

              <Form.Item name='shouldBeBetter' label='Nên làm thế nào để tốt hơn'>
                <Input.TextArea rows={8} name='shouldBeBetter' onResize={handleChangeTextarea('shouldBeBetter')} style={{ minHeight: 104 }} placeholder='Nhập góp ý nên làm thế nào để tốt hơn' />
              </Form.Item>
            </Form>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export const renderForm_EBM_DanhGiaCuocGoi = (params) => (text, record, index) => {
  // console.log('reivew-id', record);
  return <DetailsModal params={params} record={record} text={text} index={index} />;
};
