/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { AutoComplete, Input, InputNumber, Radio, Checkbox, Select, DatePicker, TimePicker, Rate, Slider, Switch, TreeSelect, Mentions, Button } from 'antd';
import { CheckOutlined, StopOutlined } from '@ant-design/icons';

const SelectMultiple = React.forwardRef((props, ref) => {
  return <Select ref={ref} {...props} allowClear optionFilterProp='label' showArrow mode='multiple' />;
});

const SelectWithSearch = React.forwardRef((props, ref) => {
  return <Select ref={ref} {...props} allowClear optionFilterProp='label' showArrow showSearch virtual={false} />;
});

const CheckBoxGroup = React.forwardRef((props, ref) => {
  return (
    <React.Fragment>
      <div style={{ marginTop: 4, marginBottom: 12 }}>
        <Button
          type='default'
          size='small'
          icon={<CheckOutlined style={{ color: '#52C41A' }} />}
          onClick={() => {
            const all = _.map(props.options, 'value');
            props.onChange(all);
          }}
        >
          Chọn tất cả
        </Button>

        <Button
          style={{ marginLeft: 6 }}
          type='default'
          size='small'
          icon={<StopOutlined style={{ color: '#FF4D4F' }} />}
          onClick={() => {
            props.onChange([]);
          }}
        >
          Bỏ chọn tất cả
        </Button>
      </div>
      <Checkbox.Group ref={ref} {...props} />
    </React.Fragment>
  );
});

// const MyDatePicker = React.forwardRef((props, ref) => {
//   const dateFormat = 'YYYY-MM-DD';
//   return (
//     <DatePicker
//       ref={ref}
//       {...props}
//       format={dateFormat}
//       onChange={(date, dateString) => {
//         props.onChange(moment(dateString));
//       }}
//     />
//   );
// });

const MyAutoComplete = React.forwardRef((props, ref) => {
  return <AutoComplete ref={ref} {...props} filterOption={(inputValue, option) => option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1} />;
});

const inputComponents = {
  Input,
  InputNumber,
  // DatePicker: MyDatePicker,
  DatePicker,
  'DatePicker.RangePicker': DatePicker.RangePicker,
  TimePicker,
  Select,
  SelectWithSearch,
  'Select.Multiple': SelectMultiple,
  Radio,
  'Radio.Group': Radio.Group,
  Switch,
  Checkbox,
  'Checkbox.Group': CheckBoxGroup,
  Rate,
  Slider,
  TreeSelect,
  Mentions,
  AutoComplete: MyAutoComplete,
};

export default inputComponents;
