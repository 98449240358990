/* eslint-disable react/prop-types */
import React from 'react';
import { CalendarOutlined } from '@ant-design/icons';
import { List, Space } from 'antd';
import colors from 'constants/colors';
import moment from 'moment';
import numeral from 'numeral';

function ClassCard({ item, onClick }) {
  // const c = _.countBy(item.attendances, 'attendanceStatus');
  // let chuaDiemDanh = c.CHUADIEMDANH;
  // if (chuaDiemDanh === undefined) chuaDiemDanh = 0;
  // if (item.attendances === null) chuaDiemDanh = item.numberOfStudents;

  const done = item.attendances === item.numberOfStudents;

  return (
    <List.Item
      style={{ cursor: 'pointer' }}
      onClick={() => {
        if (onClick && typeof onClick === 'function') {
          onClick();
        }
      }}
    >
      <List.Item.Meta
        title={
          <div className='mb-2' style={{ display: ' flex', justifyContent: 'space-between', color: colors.primary }}>
            <span>{`${item.id}: ${item.className}`}</span>
          </div>
        }
        style={{ color: colors.primary }}
        description={
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Space direction='vertical' size='small'>
              {item.schedules.map((s) => {
                const isToday = moment().format('dddd') === s.dayOfWeek;
                return (
                  <Space key={s.dayOfWeek}>
                    <CalendarOutlined />
                    <div style={{ width: 40, color: isToday ? '#000' : '', fontWeight: isToday ? 600 : 400 }}>{s.dayOfWeek.substring(0, 3)}</div>
                    <div style={{ color: isToday ? '#000' : '', fontWeight: isToday ? 600 : 400 }}>{`${numeral(s.hourTimeIn).format('00')}:${numeral(s.minuteTimeIn).format('00')}`}</div>
                    <div style={{ color: isToday ? '#000' : '', fontWeight: isToday ? 600 : 400 }}>{`${numeral(s.hourTimeOut).format('00')}:${numeral(s.minuteTimeOut).format('00')}`}</div>
                  </Space>
                );
              })}
            </Space>
            <div style={{ height: 60, width: 60, backgroundColor: done ? 'green' : colors.gray[100], color: done ? 'white' : 'inherit', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <div style={{ fontWeight: 700 }}>
                <span>{item.attendances}</span>
                <span className='mx-1'>/</span>
                <span>{item.numberOfStudents}</span>
              </div>
            </div>
          </div>
        }
      />
    </List.Item>
  );
}

export default ClassCard;
