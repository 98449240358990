import { query } from 'helpers/QueryHelper';
import { useMutation } from 'react-query';

const useGettingCourse = () => {
  const { data, isLoading, mutate: getCurriculumId } = useMutation((curriculumId: number) => {
    return query('p_AMES247_GetCourse', { curriculumId }, 'SmartEducation');
  });

  return { data, isLoading, getCurriculumId };
};

export default useGettingCourse;
