import { createStore, compose, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import rootReducer from './rootReducer';
import { middlewares, sagaMiddleware } from './middlewares';
import rootSagas from './rootSagas';
// REDUX TOOLS
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// REDUX STORE
const store = createStore(
  persistedReducer,
  compose(
    composeEnhancers(applyMiddleware(...middlewares)),
    // REDUX TOOLS
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
  ),
);

const persistor = persistStore(store);

sagaMiddleware.run(rootSagas);
export { store, persistor };
