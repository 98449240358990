import React, { Component } from 'react';
import { Form, DatePicker, Row, Col, Input, Select, Button, Typography, Table, Divider, Checkbox, Progress } from 'antd';

// HIGH CHARTS
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import moment from 'moment';
import numeral from 'numeral';
import _ from 'lodash';
import axios from 'axios';

import ReportHelper from '../helpers/ReportHelper';

const highchartOptions = {
  chart: {
    type: 'bar',
  },
  title: {
    text: 'Báo cáo doanh thu',
  },
  subtitle: {
    text: 'Source: AMES ENGLISH',
  },
  credits: {
    enabled: false,
  },
  // legend: {
  //   layout: 'vertical',
  //   align: 'right',
  //   verticalAlign: 'top',
  //   x: -40,
  //   y: 80,
  //   floating: true,
  //   borderWidth: 1,
  //   backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
  //   shadow: true,
  // },
  xAxis: {
    categories: [],
    title: {
      text: null,
    },
  },
  yAxis: {
    min: 0,
    title: {
      text: 'Doanh thu (VNĐ)',
      align: 'high',
    },
    labels: {
      overflow: 'justify',
    },
  },
  tooltip: {
    valueSuffix: ' VNĐ',
  },
  plotOptions: {
    bar: {
      dataLabels: {
        enabled: true,
      },
    },
    series: {
      cursor: 'pointer',
      colorByPoint: true, // Multi colors
    },
  },
};

// ------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------
class RevenuesPage extends Component {
  constructor(props) {
    super(props);
    this.day = 1;
    this.state = {
      race_results: [],
      highchartOptions: null,
    };
  }

  componentDidMount() {
    const month = moment().month() + 1;
    const year = moment().year();

    const promises = [];

    for (let day = 1; day <= moment().date(); day++) {
      promises.push(
        ReportHelper.Query('p_API_EBM_GetRevenues', {
          month,
          year,
          fromDay: 1,
          toDay: day,
        }),
      );
    }

    axios.all(promises).then((results) => {
      const { race_results } = this.state;
      results.forEach((value, index) => {
        race_results.push(value);
      });

      this.setState({ race_results }, () => {
        setInterval(this.renderChart, 2000);
      });
    });
  }

  renderChart = () => {
    const results = this.state.race_results[this.day - 1];
    console.log(results);
    if (results === undefined) {
      this.day = 1;
      return;
    }

    const numberOfDivisions = results.length;
    const top1DivisionName = _.head(results).divisionName;
    const top1Amount = _.head(results).amount;
    const top10Amount = results[9].amount;

    results.forEach((item) => {
      item.top1DivisionName = top1DivisionName;
      item.numberOfDivisions = numberOfDivisions;
      item.rankText = item.rank === 1 ? 'Quán quân 🏆' : item.rank + ' / ' + numberOfDivisions;
      item.distanceToTop1Amount = top1Amount - item.amount;
      item.distanceToTop10Amount = top10Amount - item.amount;
      // console.log(item);
    });

    // Highc0harts: Options
    const series = [
      {
        name: 'Doanh thu',
        data: _.reduce(
          results,
          (accumulator, currentValue) => {
            accumulator.push(currentValue.amount);
            return accumulator;
          },
          [],
        ),
      },
    ];
    let categories = [];
    results.forEach((item) => {
      categories.push(item.divisionName);
    });

    highchartOptions.xAxis.categories = categories;
    highchartOptions.series = series;

    highchartOptions.chart.height = results.length * 30 + 120;

    highchartOptions.title.text = 'Báo cáo doanh thu, ngày ' + this.day + '/' + (moment().month() + 1);

    this.setState({ highchartOptions });
    this.day++;
  };

  render() {
    return (
      <div>
        <div>
          <HighchartsReact allowChartUpdate={true} immutable={false} updateArgs={[true, true, true]} highcharts={Highcharts} options={this.state.highchartOptions} />
        </div>
      </div>
    );
  }
}

const form = RevenuesPage;
export default form;
