import { query } from 'helpers/QueryHelper';
import moment from 'moment';
import { useCallback } from 'react';
import { useQuery } from 'react-query';

type useExerciseProps = {
  classId: number;
  studentId: number;
};

type itemProps = {
  id: string;
  title: string;
  checked: number;
  classId: number;
  date: string;
  isNow: number;
  star: number;
  completePercent: number;
};

const useGettingSessions = (props: useExerciseProps) => {
  const { classId, studentId } = props;

  const KEY = ['[p_API_MYAMES_ATTANDANCE_GetSessions_QRCode]', classId, studentId];
  const { data, isLoading, isError } = useQuery(KEY, () => {
    return query('[p_API_MYAMES_ATTANDANCE_GetSessions_QRCode]', { classId, studentId, date: moment().format('YYYY-MM-DD HH:mm:ss') }, 'SmartEducation');
  });

  /**
   * Gợi ý giao bài trong danh sách, nếu không có gợi ý thì lấy bài chưa được giao gần nhất.
   * @returns Vị trí gợi ý (kiểu number)
   */
  const getAutoSuggestion = useCallback(() => {
    let number = 0;
    if (!isLoading) {
      const suggestedLocation = data.findIndex((item: itemProps) => item.isNow === 0);
      const nearestAssignedLocation = data.findIndex((item: itemProps) => item.checked === 0);
      number = suggestedLocation !== -1 ? suggestedLocation : nearestAssignedLocation;
    }
    return number + 1;
  }, [data, isLoading]);

  /**
   * Kiểm tra bài đã được giao chưa?
   * @returns true: đã giao | false: chưa giao
   */
  const isAssigned = useCallback(
    (id: string) => {
      let result = false;
      if (!isLoading) result = data.findIndex((item: itemProps) => item.id === id && item.checked !== 0) > -1;
      return result;
    },
    [data, isLoading],
  );

  return {
    data,
    isLoading,
    isError,
    getAutoSuggestion,
    isAssigned,
    KEY,
  };
};

export default useGettingSessions;
