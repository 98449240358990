import React from 'react';
import { useLocation, useHistory } from 'react-router';
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Form, Input, Button, Checkbox } from 'antd';
import { UserOutlined, LockOutlined, KeyOutlined, GoogleOutlined, FacebookFilled, AppleFilled } from '@ant-design/icons';
import firebase from 'firebase/app';
import { login } from '../actions';
import { auth } from '../../../firebase';
import routes from '../../../constants/routes';

import logo from '../../../assets/logo.svg';

const { Title } = Typography;

function Login() {
  const location = useLocation();
  const history = useHistory();
  const loading = useSelector((state) => state.auth.loading);
  const loggedInUser = useSelector((state) => state.auth.loggedInUser);
  const dispatch = useDispatch();

  const onFinish = (values) => {
    dispatch(login(values.username, values.password));
  };

  const onGoogleLogin = () => {
    // Start a sign in process for an unauthenticated user.
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope('profile');
    provider.addScope('email');
    auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL).then(() => {
      auth
        .signInWithPopup(provider)
        .then(() => {
          if (location.state.from) {
            history.push(location.state.from.pathname);
          } else {
            history.push(routes.HOME);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  const onFacebookLogin = () => {
    // Start a sign in process for an unauthenticated user.
    const provider = new firebase.auth.FacebookAuthProvider();

    auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL).then(() => {
      auth
        .signInWithPopup(provider)
        .then((result) => {
          console.log(result);
          if (location.state.from) {
            history.push(location.state.from.pathname);
          } else {
            history.push(routes.HOME);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  return (
    <React.Fragment>
      <div className='flex  justify-center items-center flex-1 -mt-12 h-screen'>
        <div className='shadow-2xl p-12 flex-col text-center'>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: 64 }}>
            <div style={{ paddingRight: 12 }}>
              <img src={logo} alt='Logo' style={{ height: 32 }} />
            </div>
            <Title level={3}>SMART EDUCATION</Title>
          </div>
          <div style={{ marginBottom: 12 }}>
            <span className='text-md'>Dynamic reports system</span>
          </div>
          <Form
            name='login-form'
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            style={{ minWidth: 300 }}
          >
            <Form.Item name='username' rules={[{ required: true, message: 'Chưa nhập tên đăng nhập!' }]}>
              <Input prefix={<UserOutlined style={{ color: '#1890ff' }} />} placeholder='Tên đăng nhập' size='large' />
            </Form.Item>
            <Form.Item name='password' rules={[{ required: true, message: 'Chưa nhập mật khẩu!' }]}>
              <Input.Password prefix={<LockOutlined style={{ color: '#1890ff' }} />} placeholder='Mật khẩu' size='large' />
            </Form.Item>
            <Form.Item name='remember' valuePropName='checked'>
              <Checkbox>Nhớ thông tin đăng nhập</Checkbox>
            </Form.Item>
            <Form.Item>
              <Button size='large' loading={loading} icon={<KeyOutlined />} type='ghost' htmlType='submit' style={{ width: '100%' }}>
                Đăng nhập
              </Button>
              <div style={{ display: 'flex', flex: 1, alignItems: 'center', padding: 12 }}>
                <div style={{ height: 1, backgroundColor: '#bdc3c7', flex: 1 }} />
                <div style={{ marginLeft: 12, marginRight: 12 }}>OR</div>
                <div style={{ height: 1, backgroundColor: '#bdc3c7', flex: 1 }} />
              </div>
              <Button size='large' loading={loading} icon={<GoogleOutlined />} type='primary' danger htmlType='button' style={{ width: '100%' }} onClick={onGoogleLogin}>
                Đăng nhập bằng Google
              </Button>
              <div style={{ height: 12 }} />
              <Button size='large' loading={loading} icon={<FacebookFilled />} type='primary' htmlType='button' style={{ width: '100%' }} onClick={onFacebookLogin}>
                Đăng nhập bằng Facebook
              </Button>
              <div style={{ height: 12 }} />
              <Button size='large' loading={loading} icon={<AppleFilled />} type='default' htmlType='button' style={{ width: '100%' }} onClick={onGoogleLogin}>
                Đăng nhập bằng Apple
              </Button>
            </Form.Item>

            <div>
              Hoặc
              <a href=''> đăng ký tài khoản!</a>
              <br />
              <a href=''>Quên mật khẩu</a>
            </div>
          </Form>
          {loggedInUser && location.state && <Redirect to={location.state.from && location.state.from.pathname} />}
        </div>
      </div>
    </React.Fragment>
  );
}

export default Login;
