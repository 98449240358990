// import StarFilled from '@ant-design/icons/lib/icons/StarFilled';
import { StarFilled } from '@ant-design/icons';
import { Button, Col, Collapse, Empty, Radio, Row, Skeleton, Space } from 'antd';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { SessionsTypeProps } from '../AssignmentSessions';
import useAddingSessions from './useAddingSessions';
import useGettingSessions from './useGettingSessions';

const { Panel } = Collapse;

type ClassExerciseProps = {
  classId: number;
  studentId: number;
  onBack?: Dispatch<SetStateAction<SessionsTypeProps>>;
};

type itemProps = {
  id: string;
  checked: number;
  title: string;
  star: number;
  completePercent: number;
};

const ClassSessions = ({ classId, studentId, onBack }: ClassExerciseProps) => {
  const { data, isLoading, isError, getAutoSuggestion, isAssigned, KEY } = useGettingSessions({ classId, studentId });
  const { handleAssignment } = useAddingSessions();
  const [value, setValue] = useState<number>();

  const extraFeatures = () => {
    if (onBack && typeof onBack === 'function') {
      return (
        <Button key='0' onClick={() => onBack('assignment')} size='small'>
          Quay lại
        </Button>
      );
    }
    return null;
  };

  if (isLoading) {
    return (
      <Collapse defaultActiveKey={onBack && typeof onBack === 'function' ? '1' : ''}>
        <Panel header='Bài tập trong lớp' key='1' extra={extraFeatures()}>
          <Skeleton active paragraph={{ rows: 2 }} />
        </Panel>
      </Collapse>
    );
  }

  if (isError) {
    return (
      <Collapse defaultActiveKey={onBack && typeof onBack === 'function' ? '1' : ''}>
        <Panel header='Bài tập trong lớp' key='1' extra={extraFeatures()}>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </Panel>
      </Collapse>
    );
  }

  return (
    <Collapse>
      <Panel header='Bài tập trong lớp' key='1' extra={extraFeatures()}>
        <Radio.Group defaultValue={getAutoSuggestion()} onChange={(e) => setValue(e.target.value)} style={{ width: '100%' }} value={value}>
          {/* Thêm space để các option hở ra */}
          <Space direction='vertical' style={{ width: '100%' }}>
            {data.map((item: itemProps, index: number) => (
              <Row align='middle' justify='space-between' key={item.id}>
                <Col span={12}>
                  {/* Giá trị radio trong antd bắt đầu tính từ 1, để 0 (option 0) thì nó tự động chọn option 1 */}
                  <Radio disabled={item.checked !== 0} value={index + 1}>
                    {item.title}
                  </Radio>
                </Col>
                <Col span={12} style={{ height: 32, textAlign: 'right' }}>
                  {isAssigned(item.id) ? (
                    <Button icon={<StarFilled style={{ color: '#f9d414' }} />} type='text'>
                      {`${item.star} | ${item.completePercent}%`}
                    </Button>
                  ) : (
                    (value || getAutoSuggestion()) - 1 === index && (
                      <Button onClick={() => handleAssignment({ classId, sessionId: item.id, KEY, studentId })} type='primary'>
                        Giao bài
                      </Button>
                    )
                  )}
                </Col>
              </Row>
            ))}
          </Space>
        </Radio.Group>
      </Panel>
    </Collapse>
  );
};

ClassSessions.defaultProps = {
  onBack: undefined,
};

export default ClassSessions;
