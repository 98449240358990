/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
import { Form, DatePicker, Row, Col, Input, Select, Button, Typography, Table, Divider, Checkbox, Progress } from 'antd';
import numeral from 'numeral';

import { query } from '../../helpers/QueryHelper';

// ------------------------------------------------------------------------------------------------
const { Option } = Select;

const columns = [
  {
    title: 'Table Name',
    dataIndex: 'tableName',
    key: 'tableName',
    width: '50%',
    align: 'left',
    render: (text, record) => <div style={{ fontWeight: '700' }}>{`${text}`}</div>,
  },
  {
    title: () => (
      <span style={{ whiteSpace: 'nowrap' }} className='ant-table-column-title'>
        Row Count
      </span>
    ),
    dataIndex: 'rowCounts',
    key: 'rowCounts',
    align: 'right',
    render: (text, record) => <div style={{ fontWeight: '400' }}>{`${numeral(text).format('0,000')}`}</div>,
    sorter: (a, b) => a.rowCounts - b.rowCounts,
  },
  {
    title: () => (
      <span style={{ whiteSpace: 'nowrap' }} className='ant-table-column-title'>
        Total Space (KB)
      </span>
    ),
    dataIndex: 'totalSpaceKB',
    key: 'totalSpaceKB',
    align: 'right',
    render: (text, record) => <div style={{ fontWeight: '400' }}>{`${numeral(text).format('0,000')}`}</div>,
    sorter: (a, b) => a.totalSpaceKB - b.totalSpaceKB,
  },
  {
    title: () => (
      <span style={{ whiteSpace: 'nowrap' }} className='ant-table-column-title'>
        Total Space (MB)
      </span>
    ),
    dataIndex: 'totalSpaceMB',
    key: 'totalSpaceMB',
    align: 'right',
    render: (text, record) => <div style={{ fontWeight: '400' }}>{`${numeral(text).format('0,000')}`}</div>,
    sorter: (a, b) => a.totalSpaceMB - b.totalSpaceMB,
  },
  {
    title: () => (
      <span style={{ whiteSpace: 'nowrap' }} className='ant-table-column-title'>
        Used Space (KB)
      </span>
    ),
    dataIndex: 'usedSpaceKB',
    key: 'usedSpaceKB',
    align: 'right',
    render: (text, record) => <div style={{ fontWeight: '400' }}>{`${numeral(text).format('0,000')}`}</div>,
    sorter: (a, b) => a.usedSpaceKB - b.usedSpaceKB,
  },
  {
    title: () => (
      <span style={{ whiteSpace: 'nowrap' }} className='ant-table-column-title'>
        Used Space (MB)
      </span>
    ),
    dataIndex: 'usedSpaceMB',
    key: 'usedSpaceMB',
    align: 'right',
    render: (text, record) => <div style={{ fontWeight: '400' }}>{`${numeral(text).format('0,000')}`}</div>,
    sorter: (a, b) => a.usedSpaceMB - b.usedSpaceMB,
  },
  {
    title: () => (
      <span style={{ whiteSpace: 'nowrap' }} className='ant-table-column-title'>
        Unused Space (KB)
      </span>
    ),
    dataIndex: 'unusedSpaceKB',
    key: 'unusedSpaceKB',
    align: 'right',
    render: (text, record) => <div style={{ fontWeight: '400' }}>{`${numeral(text).format('0,000')}`}</div>,
    sorter: (a, b) => a.unusedSpaceKB - b.unusedSpaceKB,
  },
  {
    title: () => (
      <span style={{ whiteSpace: 'nowrap' }} className='ant-table-column-title'>
        Unused Space (MB)
      </span>
    ),
    dataIndex: 'unusedSpaceMB',
    key: 'unusedSpaceMB',
    align: 'right',
    render: (text, record) => <div style={{ fontWeight: '400' }}>{`${numeral(text).format('0,000')}`}</div>,
    sorter: (a, b) => a.unusedSpaceMB - b.unusedSpaceMB,
  },
];

// ------------------------------------------------------------------------------------------------
class TableSizePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tables: [],
    };
  }

  componentDidMount() {
    query('p_API_MONITORING_GetTableSizes', {}, 'SmartEducation').then((result) => {
      console.log(result);
      this.setState({ tables: result });
    });
  }

  render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
    };

    return (
      <div>
        <Form
          {...formItemLayout}
          initialValues={{ database: 'SmartEducation' }}
          onFinish={(values) => {
            const { database } = values;
            query('p_API_MONITORING_GetTableSizes', {}, database).then((results) => {
              console.log(results);
              this.setState({ tables: results });
            });
          }}
        >
          <Form.Item label='Database' name='database' rules={[{ required: false, message: 'Chưa nhập dữ liệu' }]}>
            <Select>
              <Option value='SmartEducation'>Smart Education</Option>
              <Option value='Sgk'>Smart Education (SGK)</Option>
              <Option value='EBM'>EBM.SOFTECH.EDU.VN</Option>
              <Option value='OFFICE'>ASPNETVN.PORTAL.OFFICE</Option>
            </Select>
          </Form.Item>
          <Form.Item
            wrapperCol={{
              xs: { span: 24, offset: 0 },
              sm: { span: 18, offset: 6 },
            }}
          >
            <Button type='primary' htmlType='submit'>
              Tổng hợp
            </Button>
          </Form.Item>
        </Form>

        <div>
          <Table columns={columns} rowKey='tableName' dataSource={this.state.tables} pagination={false} bordered />
        </div>
      </div>
    );
  }
}

const form = TableSizePage;
export default form;
