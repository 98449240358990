/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import { Button, Checkbox, Col, Form, Input, Modal, Row, notification } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';

import dayjs from 'dayjs';
import { execute } from 'helpers/QueryHelper';
import { isNull } from 'lodash';

const CheckInDetailsModal = ({ record }) => {
  const loggedInUser = useSelector((state) => state.auth.loggedInUser);

  const [form] = Form.useForm();

  const [visible, setVisible] = React.useState(false);
  const [checkBox, setCheckBox] = React.useState(null);
  const [textAreaHeights, setTextAreaHeight] = React.useState({
    content: 0,
    checkIn: 0,
    note: 0,
  });
  const [totalTextAreaHeight, setTotalTextAreaHeight] = React.useState(0);
  const openModal = () => {
    setVisible(true);
  };

  const onChange = (e) => {
    setCheckBox(e.target.checked);
  };
  React.useEffect(() => {
    form.setFieldsValue({
      content: record?.actionNote?.replace(/<[^>]+>/g, ''),
      aoName: record?.aoName,
      callDate: dayjs(record?.actionDate).format('DD/MM/YYYY HH:mm'),
      divisionName: record?.divisionName,
      phone: record?.studentPhone,
      contactId: record?.studentId,
      contactName: record?.studentName,
      checkInNotes: record?.checkInNotes,
    });
    setCheckBox(isNull(record?.isNotCorrectCheckInType) ? false : record?.isNotCorrectCheckInType);
  }, [form, record]);

  const handleChangeTextarea = (name) => (e) => {
    setTextAreaHeight((prev) => ({
      ...prev,
      [name]: e.height,
    }));
  };

  React.useEffect(() => {
    const heightArray = Object.values(textAreaHeights);
    const totalHeight = heightArray.reduce((sum, height) => sum + height, 0);
    setTotalTextAreaHeight(totalHeight);
  }, [textAreaHeights]);

  return (
    <React.Fragment>
      {record?.id && (
        <div style={{ width: '100%', fontWeight: '700', whiteSpace: 'nowrap', cursor: 'pointer', color: '#1890ff' }} onClick={() => openModal(record?.id)}>
          Đánh giá
        </div>
      )}
      <Modal
        centered
        width='80vw'
        title='Đánh giá hoạt động Check-in'
        visible={visible}
        onCancel={() => setVisible(false)}
        bodyStyle={{ height: `calc(42vh + ${totalTextAreaHeight}px)` }}
        footer={[
          <Button
            type='primary'
            key='submit'
            onClick={() => {
              form.submit();
            }}
          >
            Lưu thông tin
          </Button>,
          <Button
            type='default'
            key='close-modal'
            onClick={() => {
              setVisible(false);
            }}
          >
            Đóng
          </Button>,
        ]}
      >
        <div style={{ display: 'flex', flexDirection: 'column', maxHeight: '75vh' }}>
          <div style={{ paddingTop: 12 }}>
            <Form
              labelCol={{
                span: 6,
              }}
              wrapperCol={{
                span: 18,
              }}
              form={form}
              name='basic'
              initialValues={{}}
              onFinish={(values) => {
                // console.log(values);

                const parameters = {
                  id: record.id,
                  isNotCorrectCheckInType: checkBox,
                  checkInNotes: values.checkInNotes,
                  createdBy: loggedInUser.email,
                };

                execute('p_API_EBM_Report_ActionCheckIn_Update', parameters, 'Report')
                  .then((data) => {
                    setVisible(false);
                    notification.success({
                      message: 'Cập nhập đánh giá hoạt động Check-in thành công',
                    });
                  })
                  .catch((error) => {
                    alert('Có lỗi khi lưu thông tin. Liên hệ AMES - IT để được hỗ trợ');
                  });
              }}
            >
              <Row gutter={[24, 24]}>
                <Col lg={12}>
                  <Form.Item label='Chi nhánh/Trung tâm' name='divisionName'>
                    <Input disabled style={{ color: 'black' }} />
                  </Form.Item>
                </Col>
                <Col lg={12}>
                  <Form.Item label='Tên AO' name='aoName'>
                    <Input disabled style={{ color: 'black' }} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[24, 24]}>
                <Col lg={12}>
                  <Form.Item label='ID' name='contactId'>
                    <Input disabled style={{ color: 'black' }} />
                  </Form.Item>
                </Col>
                <Col lg={12}>
                  <Form.Item label='Tên học viên' name='contactName'>
                    <Input disabled style={{ color: 'black' }} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[24, 24]}>
                <Col lg={12}>
                  <Form.Item label='Số máy gọi' name='phone'>
                    <Input disabled style={{ color: 'black' }} />
                  </Form.Item>
                </Col>
                <Col lg={12}>
                  <Form.Item label='Thời gian gọi' name='callDate'>
                    <Input disabled style={{ color: 'black' }} />
                  </Form.Item>
                </Col>
              </Row>
              <hr style={{ marginBlock: '1rem' }} />
              <Form.Item name='content' label='Nội dung cuộc gọi'>
                <Input.TextArea rows={4} name='content' onResize={handleChangeTextarea('content')} disabled style={{ color: 'black' }} />
              </Form.Item>
              <Form.Item name='isCorrectCheckInType' label='Đánh giá hoạt động check-in'>
                <Checkbox onChange={onChange} value={checkBox} checked={checkBox}>
                  Không đúng loại check-in
                </Checkbox>
              </Form.Item>
              <Form.Item
                name='checkInNotes'
                label='Lý do'
                rules={[
                  {
                    required: checkBox,
                    message: 'Vui lòng nhập lý do!',
                  },
                ]}
              >
                <Input.TextArea rows={4} name='checkInNotes' onResize={handleChangeTextarea('checkInNotes')} style={{}} />
              </Form.Item>
            </Form>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export const renderForm_EBM_DanhGiaCheckIn = (params) => (text, record, index) => {
  return <CheckInDetailsModal params={params} record={record} text={text} index={index} />;
};
