/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { Card } from 'antd';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import numeral from 'numeral';

export default class Drive extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pieChartOptions: {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie',
          height: 400,
        },
        credits: {
          enabled: false,
        },
        title: {
          text: null,
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
        },
        plotOptions: {
          pie: {
            // allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: false,
              format: '<b>{point.name}</b>: {point.percentage:.1f} %',
            },
            showInLegend: true,
          },
        },
        series: [
          {
            name: 'MB',
            data: [
              {
                name: `Free (${numeral(props.data.freeMB / 1024).format('0,000.00')} GB)`,
                y: props.data.freePercentage,
                sliced: true,
                selected: true,
                color: '#a0d911',
              },
              {
                name: `Used (${numeral((props.data.sizeMB - props.data.freeMB) / 1024).format('0,000.00')} GB)`,
                y: 100 - props.data.freePercentage,
                color: '#2c3e50',
              },
            ],
          },
        ],
      },
    };
  }

  render() {
    return (
      <Card title={this.props.title} bordered={false} style={{ width: '100%' }}>
        <HighchartsReact allowChartUpdate immutable={false} updateArgs={[true, true, true]} highcharts={Highcharts} options={this.state.pieChartOptions} />
      </Card>
    );
  }
}
