/* eslint-disable react/prop-types */
import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import loadable from '@loadable/component';
import { Layout } from 'antd';

// Components
import Dashboard from 'pages/TFS/Dashboard';
import TeacherPage from 'pages/Calendar/TeacherPage';
import DynamicReportPage from 'pages/DynamicReport';
import TemplatedEmails from 'pages/AWS/SES/TemplatedEmails';
import SearchPage from 'pages/DynamicReport/SearchPage';
import Loading from '../components/Loading';
import MainHeader from '../components/MainHeader';

// Pages
import MonitorPage from '../pages/Monitor';
import TableSizePage from '../pages/Monitor/TableSizePage';

import routes from '../constants/routes';

import RevenuesPage from '../pages/RevenuesPage';
import RaceRevenuesPage from '../pages/RaceRevenuesPage';
import RealtimeRevenuesPage from '../pages/RealtimeRevenuesPage';
import MyAmesUsersPage from '../pages/MyAmesUsersPage';
import HighChartPage from '../pages/HighChartPage';
import Editor from '../pages/Editor';
import WorkChatLogPage from '../pages/Log/WorkChatLogPage';
import Attendance from 'pages/EBM/Teacher/Attendance';
// import HomePage from '../pages/HomePage';

// CALENDAR

// DYNAMIC REPORT

const { Content } = Layout;

const HomePage = loadable(() => import('pages/HomePage'), {
  fallback: <Loading />,
});

function AuthenticatedLayout({ location }) {
  // HOOKS
  const loggedInUser = useSelector((state) => state.auth.loggedInUser);

  if (!loggedInUser) {
    return <Redirect to={{ pathname: routes.ACCOUNT_LOGIN, state: { from: location } }} />;
  }
  return (
    <React.Fragment>
      <Layout style={{ minHeight: '100vh' }}>
        {/* <MainSider collapsed={this.state.collapsed} /> */}
        <Layout>
          <MainHeader />
          <Content>
            {/* <MainMenu /> */}
            <div style={{ padding: 24 }}>
              <Route exact path={routes.HOME} component={HomePage} />
              <Route exact path={routes.REVENUES} component={RevenuesPage} />
              <Route exact path={routes.EDITOR} component={Editor} />
              <Route exact path={routes.REVENUES_MONTH} component={RevenuesPage} />
              <Route exact path={routes.REVENUES_RACE} component={RaceRevenuesPage} />
              <Route exact path={routes.REVENUES_REALTIME} component={RealtimeRevenuesPage} />
              <Route exact path={routes.MYAMES_USERS} component={MyAmesUsersPage} />
              <Route exact path={routes.HIGHTCHART} component={HighChartPage} />

              <Route exact path={routes.MONITOR} component={MonitorPage} />
              <Route exact path={routes.MONITOR_TABLESIZE} component={TableSizePage} />

              <Route exact path={routes.LOG_WORKCHAT} component={WorkChatLogPage} />
              <Route exact path={routes.CALENDAR_TEACHER} component={TeacherPage} />

              <Route exact path={routes.DYNAMIC_REPORT} component={DynamicReportPage} />
              <Route exact path={routes.DYNAMIC_REPORT_SEARCH} component={SearchPage} />

              <Route exact path={routes.ADMIN_AWS_SES_TEMPLATED_EMAILS} component={TemplatedEmails} />
              <Route exact path={routes.TFS_DASHBOARD} component={Dashboard} />
            </div>
          </Content>
        </Layout>
      </Layout>
    </React.Fragment>
  );
}

export default AuthenticatedLayout;
