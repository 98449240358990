/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import './index.css';
import _ from 'lodash';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { HomeOutlined, AppstoreOutlined } from '@ant-design/icons';
import { Layout, Menu } from 'antd';

import { getMenus } from 'modules/report/actions';
import RightContent from './RightContent';

const { Header } = Layout;
const { SubMenu } = Menu;

export default function MainHeader({ ...rest }) {
  // HOOKS
  const location = useLocation();
  const menus = useSelector((state) => state.report.menus);
  const dispatch = useDispatch();

  const [key, setKey] = React.useState(location.pathname);

  const handleClick = (e) => {
    setKey(e.key);
  };

  React.useEffect(() => {
    dispatch(getMenus());
  }, []);

  return (
    <React.Fragment>
      <Header style={{ backgroundColor: '#001529', padding: 0, width: '100%' }}>
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1 }}>
            <Menu theme='dark' mode='horizontal' onClick={handleClick} selectedKeys={[key]}>
              <Menu.Item key='home' icon={<HomeOutlined />}>
                <Link to='/'>Trang chủ</Link>
              </Menu.Item>
              {menus.map((item) => {
                return (
                  <SubMenu key={item.key} title={item.title} {...item.props} icon={<AppstoreOutlined />}>
                    {/* FILTER & SORT ORDER */}
                    {_.orderBy(
                      _.filter(item.subMenuItems, (o) => {
                        return o.disabled === undefined || o.disabled === false;
                      }),
                      ['sortOrder'],
                      ['asc'],
                    ).map((sub) => (
                      <Menu.Item key={sub.link} {...sub.props}>
                        <Link to={sub.link}>{sub.title}</Link>
                      </Menu.Item>
                    ))}
                  </SubMenu>
                );
              })}
            </Menu>
          </div>
          <div style={{ paddingRight: 8 }}>
            <RightContent {...rest} />
          </div>
        </div>
      </Header>
    </React.Fragment>
  );
}
