import { query } from 'helpers/QueryHelper';
import { useMutation } from 'react-query';

type GetSessionsProps = {
  courseId: string;
  classId: number;
};

const useGettingEBMSessions = () => {
  const { data, isLoading, mutate: getCourseIdAndClassId } = useMutation(({ courseId, classId }: GetSessionsProps) => {
    return query('p_AMES247_EBM_GetSessions', { courseId, classId }, 'SmartEducation');
  });
  return { data, isLoading, getCourseIdAndClassId };
};

export default useGettingEBMSessions;
