import axios from 'axios';

const authorizationKey = 'Basic 12C1F7EF9AC8E288FBC2177B7F54D';

function execute(sqlCommand, parameters = {}, applicationName = 'Default') {
  return new Promise((resolve, reject) => {
    axios({
      url: 'https://softech.cloud/api/v1.0/dynamic/execute',
      method: 'POST',
      headers: {
        Authorization: authorizationKey,
        ApplicationName: applicationName,
      },
      data: {
        sqlCommand,
        parameters,
      },
    })
      .then((response) => {
        resolve(response.data.result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function query(sqlCommand, parameters = {}, applicationName = 'Default') {
  return new Promise((resolve, reject) => {
    axios({
      url: 'https://softech.cloud/api/v1.0/dynamic/query',
      method: 'POST',
      headers: {
        Authorization: authorizationKey,
        ApplicationName: applicationName,
      },
      data: {
        sqlCommand,
        parameters,
      },
    })
      .then((response) => {
        resolve(response.data.results);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function queryExcel(sqlCommand, parameters = {}, applicationName = 'Default') {
  return new Promise((resolve, reject) => {
    axios({
      url: 'https://softech.cloud/api/v1.0/dynamic/query',
      method: 'POST',
      headers: {
        Authorization: authorizationKey,
        ApplicationName: applicationName,
      },
      data: {
        sqlCommand,
        parameters,
        exportToExcel: true,
      },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
function queryFirst(sqlCommand, parameters = {}, applicationName = 'Default') {
  return new Promise((resolve, reject) => {
    axios({
      url: 'https://softech.cloud/api/v1.0/dynamic/query/first',
      method: 'POST',
      headers: {
        Authorization: authorizationKey,
        ApplicationName: applicationName,
      },
      data: {
        sqlCommand,
        parameters,
      },
    })
      .then((response) => {
        resolve(response.data.result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export { query, queryFirst, execute, queryExcel };
