import { ReloadOutlined, StarFilled } from '@ant-design/icons';
import { Button, Col, Collapse, Empty, List, Row, Skeleton } from 'antd';
import { query } from 'helpers/QueryHelper';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import ClassSessions from '../ClassSessions';
import CurriculumsSessions from '../CurriculumsSessions';

const { Panel } = Collapse;

type SessionsProps = {
  classId: number;
  studentId: number;
};

type Response = {
  classId: number;
  completedPercent: number;
  dateAssigned: string;
  dateDeadline: string;
  days: number;
  id: number;
  sessionId: string;
  star: number;
  title: string;
};

export type SessionsTypeProps = 'assignment' | 'class' | 'curriculumns';

const AssignmentSessions = ({ classId, studentId }: SessionsProps) => {
  const [sessionsType, setSessionsType] = useState<SessionsTypeProps>('assignment');
  const [refreshVisible, setRefreshVisible] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const KEY = ['p_AMES247_EBM_Assignment_GetSession_ActiveClass', classId, studentId, refresh];
  const { data, isLoading } = useQuery(KEY, () => query('p_AMES247_EBM_Assignment_GetSession_ActiveClass', { classId, studentId }, 'SmartEducation'));

  const extraRefresh = () => {
    return (
      refreshVisible && (
        <Button
          onClick={(event) => {
            event.stopPropagation();
            setRefresh(!refresh);
          }}
          icon={<ReloadOutlined />}
          loading={isLoading}
          // icon={isLoading ? <LoadingOutlined /> : <ReloadOutlined />}
        ></Button>
      )
    );
  };

  const handleCollapse = (e: any) => {
    setRefreshVisible(!!e?.[0]);
  };
  return (
    <React.Fragment>
      {sessionsType === 'assignment' && (
        <Collapse onChange={(e) => handleCollapse(e)}>
          <Panel header='Bài tập' key='1' extra={extraRefresh()}>
            <Row>
              <Col span={12}>
                <Button type='primary' onClick={() => setSessionsType('class')}>
                  Bài tập trong lớp
                </Button>
              </Col>
              <Col span={12} style={{ textAlign: 'right' }}>
                <Button
                  onClick={(event) => {
                    setSessionsType('curriculumns');
                    event.stopPropagation();
                  }}
                >
                  Bài tập thư viện
                </Button>
              </Col>
            </Row>
            {isLoading && <Skeleton active />}
            {!isLoading && data && data.length === 0 && <Empty description='Chưa có bài tập nào được giao!' style={{ marginTop: 12 }} />}
            {!isLoading && data && data.length > 0 && (
              <List
                dataSource={data}
                renderItem={(item: Response) => (
                  <List.Item>
                    <Row align='middle' style={{ flex: 1 }}>
                      <Col span={17}>{item.title}</Col>
                      <Col span={5} style={{ textAlign: 'right' }}>
                        <StarFilled style={{ color: '#f9d414' }} />
                        {`${item.star} | ${item.completedPercent}%`}
                      </Col>
                    </Row>
                  </List.Item>
                )}
              />
            )}
          </Panel>
        </Collapse>
      )}

      {sessionsType === 'class' && <ClassSessions classId={classId} studentId={studentId} onBack={setSessionsType} />}
      {sessionsType === 'curriculumns' && <CurriculumsSessions classId={classId} queryKey={KEY} studentId={studentId} onBack={setSessionsType} />}
    </React.Fragment>
  );
};

export default AssignmentSessions;
