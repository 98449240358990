/* eslint-disable no-undef */
import './App.css';
import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
// APOLLO
import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client';
// ROUTER
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// REACT-QUERY
import { QueryClient, QueryClientProvider } from 'react-query';

import numeral from 'numeral';
import Loading from 'components/Loading';
import routes from 'constants/routes';
// Layouts
import Public from 'layouts/Public';
import Authenticated from 'layouts/Authenticated';
import NoneAuthenticated from 'layouts/NoneAuthenticated';
// import SmartEducation from 'layouts/SmartEducation';
// import EBM from 'layouts/EBM';

// Redux
import { store, persistor } from 'modules/store';

import { setLoggedInUser } from 'modules/auth/actions';
import { auth } from './firebase';

import ReportHelper from './helpers/ReportHelper';

// NUMERAL
// load a locale
numeral.register('locale', 'vn', {
  delimiters: {
    thousands: '.',
    decimal: ',',
  },
  currency: {
    symbol: 'VNĐ',
  },
});

// switch between locales
numeral.locale('vn');

// APOLLO
const client = new ApolloClient({
  uri: 'https://strapi.softech.cloud/graphql',
  cache: new InMemoryCache(),
});

// Create a query client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    // const loggedInUserString = localStorage.getItem('loggedInUser');
    // if (loggedInUserString) {
    //   const loggedInUser = JSON.parse(loggedInUserString);
    //   store.dispatch(setLoggedInUser(loggedInUser));
    // }

    const unsubscribe = auth.onAuthStateChanged((user) => {
      try {
        if (user) {
          if (user.email.indexOf('ames.edu.vn') >= 0) {
            // Get user information from EBM
            ReportHelper.GetProfileOfEBM(user.email).then((result) => {
              const loggedInUser = { ...user.providerData[0] };
              loggedInUser.externalProfile = {
                ebm: result,
              };
              window.localStorage.setItem('loggedInUser', JSON.stringify(loggedInUser));
              store.dispatch(setLoggedInUser(loggedInUser));
            });
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);
  return (
    <React.Fragment>
      {loading && (
        <div style={{ display: 'flex', height: '100vh', flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: 'white' }}>
          <Loading />
        </div>
      )}
      {!loading && (
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ApolloProvider client={client}>
              <QueryClientProvider client={queryClient}>
                <Router basename={process.env.PUBLIC_URL}>
                  <Switch>
                    <Route exact path={routes.ACCOUNT_LOGIN} component={Public} />
                    <Route path='/ebm/teacher/attendance/*' component={NoneAuthenticated} />
                    <Route path='/*' component={Authenticated} />
                  </Switch>
                </Router>
              </QueryClientProvider>
            </ApolloProvider>
          </PersistGate>
        </Provider>
      )}
    </React.Fragment>
  );
}

export default App;
