/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-eval */
/* eslint-disable react/prop-types */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-props-no-spreading */
import 'packages/DynamicForm/rowClassNames.css';
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import numeral from 'numeral';

import { Table, Skeleton } from 'antd';
import buildDisplayColumns from '../buildDisplayColumns';

// ------------------------------------------------------------------------------------------------
// REACT COMPONENT
// ------------------------------------------------------------------------------------------------
function DynamicTable({ loading, dataSource, initialTableData, expandable, rowKey }) {
  const [tableData, setTableData] = React.useState(null);
  const primaryKey = rowKey || initialTableData?.rowKey || initialTableData?.displayColumns[0].key || 'rowKey';

  React.useEffect(() => {
    if (initialTableData !== null) {
      // displayColumns
      const data = _.cloneDeep(initialTableData);
      data.displayColumns = buildDisplayColumns(initialTableData.displayColumns);

      setTableData(data);

      // console.log('🔥 Display columns', initialTableData.displayColumns);
      // console.log('👍 Final display columns', data.displayColumns);
    }
  }, [initialTableData]);

  // FIXME: Please fixed filter
  React.useEffect(() => {
    // configure filter
    if (tableData) {
      tableData.displayColumns.forEach((c) => {
        if (c.filter) {
          const filterOptions = _.map(dataSource, (r) => {
            return { text: r[c.dataIndex], value: r[c.dataIndex] };
          });

          const districtOption = _.uniqBy(filterOptions, 'text');

          c.filters = _.orderBy(districtOption, ['text'], ['asc']);
          c.onFilter = (value, record) => record[c.dataIndex].indexOf(value) === 0;
        }
      });
    }
  }, [dataSource, tableData]);

  return (
    <div style={{ marginTop: 24 }}>
      <Skeleton active loading={loading}>
        {tableData && dataSource && dataSource.length > 0 && (
          <Table
            tableLayout='auto'
            rowKey={primaryKey}
            size='small'
            // sticky
            dataSource={dataSource}
            expandable={expandable}
            columns={tableData.displayColumns}
            bordered
            loading={loading}
            pagination={false}
            scroll={tableData.tableScroll ? tableData.tableScroll : { x: true }}
            scrollToFirstRowOnChange
            rowClassName={(record, index) => {
              // Không được bỏ 2 biến record, index vì hàm eval có dùng
              let rowClassName = '';
              if (tableData.formatConditions) {
                for (let i = 0; i < tableData.formatConditions.length; i++) {
                  const condition = eval(tableData.formatConditions[i].condition);
                  if (condition) {
                    rowClassName = tableData.formatConditions[i].className;
                  }
                }
              }

              return rowClassName;
            }}
            summary={(pageData) => {
              return (
                <Table.Summary.Row className='font-bold'>
                  {tableData.displayColumns.map((c, index) => {
                    // TITLE
                    if (c.summary && c.summary.title) {
                      return (
                        <Table.Summary.Cell key={c.key}>
                          <div className={c.summary.className} style={c.summary.style}>
                            {c.summary.title}
                          </div>
                        </Table.Summary.Cell>
                      );
                    }

                    if (!c.children) {
                      if (c.summary && c.summary.visible) {
                        return (
                          <Table.Summary.Cell key={c.key}>
                            <div className={c.summary.className} style={{ whiteSpace: 'nowrap', textAlign: 'right', ...c.summary.style }}>
                              {/* SUM */}
                              {`${numeral(
                                _.reduce(
                                  pageData,
                                  (sum, record) => {
                                    return sum + record[c.dataIndex];
                                  },
                                  0,
                                ),
                              ).format(c.formatString)}${c.summary.suffixString === undefined ? '' : c.summary.suffixString}`}
                            </div>
                          </Table.Summary.Cell>
                        );
                      }
                      return <Table.Summary.Cell key={c.key} />;
                    }

                    return (
                      <React.Fragment key={`cell${index}`}>
                        {c.children.map((child) => {
                          if (child.summary && child.summary.visible) {
                            return (
                              <Table.Summary.Cell key={child.key}>
                                <div className={child.summary.className} style={{ whiteSpace: 'nowrap', textAlign: 'right', ...child.summary.style }}>
                                  {`${numeral(
                                    _.reduce(
                                      pageData,
                                      (sum, record) => {
                                        return sum + record[child.dataIndex];
                                      },
                                      0,
                                    ),
                                  ).format(child.formatString)}${child.summary.suffixString === undefined ? '' : child.summary.suffixString}`}
                                </div>
                              </Table.Summary.Cell>
                            );
                          }
                          return <Table.Summary.Cell key={child.key} />;
                        })}
                      </React.Fragment>
                    );
                  })}
                </Table.Summary.Row>
              );
            }}
          />
        )}
      </Skeleton>
    </div>
  );
}

DynamicTable.propTypes = {
  loading: PropTypes.bool,
  dataSource: PropTypes.array.isRequired,
  initialTableData: PropTypes.object,
  expandable: PropTypes.object,
  rowKey: PropTypes.string,
};

DynamicTable.defaultProps = {
  loading: false,
  rowKey: null,
  initialTableData: null,
  expandable: null,
};

export default DynamicTable;
