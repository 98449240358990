import { put, takeLatest, select } from 'redux-saga/effects';
import _ from 'lodash';

import * as ActionTypes from '../actions/types';
import ReportHelper from '../../../helpers/ReportHelper';

function* getMenus() {
  try {
    const menus = yield ReportHelper.GetSideBarMenuItems();
    // try {
    //   const forms = yield ReportHelper.GetForms();
    //   firestore.collection('reports').doc('data').set({ forms, menus });
    // } catch (firebaseError) {
    //   console.log('firebaseError', firebaseError);
    // }

    // GET loggedInUser
    const loggedInUser = yield select((state) => state.auth.loggedInUser);

    // filter menus by roles
    if (loggedInUser.username === 'admin') {
      yield put({ type: ActionTypes.REPORT_GET_MENUS_SUCCESS, menus });
    } else if (loggedInUser.username === 'hr') {
      const result = _.filter(menus, (m) => {
        // Exclude Admin roles
        return m.id !== '5f2e981055b8cafd54e9e71c';
      });

      yield put({ type: ActionTypes.REPORT_GET_MENUS_SUCCESS, menus: result });
    } else {
      const result = _.filter(menus, (m) => {
        return (
          _.find(m.report_roles, (r) => {
            return r.roleId === loggedInUser.externalProfile.ebm.groupId;
          }) ||
          _.find(m.report_users, (u) => {
            return u.email === loggedInUser.email;
          })
        );
      });

      yield put({ type: ActionTypes.REPORT_GET_MENUS_SUCCESS, menus: result });
    }
  } catch (error) {
    yield put({
      type: ActionTypes.REPORT_GET_MENUS_ERROR,
      error,
    });
  }
}

// sagas
export default function* sagas() {
  yield takeLatest(ActionTypes.REPORT_GET_MENUS, getMenus);
}
