import { notification } from 'antd';
import axios, { AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

type AddEBMSessionProps = {
  classId: number;
  sessionId: string;
  date: string;
  userId: string;
};

type Response = {
  message: string;
  description: string;
  results: string;
};

type useAddingSessionsProps = {
  classId: number;
  date: string;
  sessionId: string;
  KEY?: (string | number | boolean)[]; // boolean để refresh
  userId: string;
};

function addEBMSessions({ classId, date, sessionId, userId }: AddEBMSessionProps) {
  // Gọi URLSearchParams vì Content-Type 'application/x-www-form-urlencoded'
  const params = new URLSearchParams();
  params.append('classId', classId.toString());
  params.append('date', date);
  params.append('sessionId', sessionId);
  params.append('userId', userId);
  return new Promise((resolve, reject) => {
    axios({
      url: 'https://cloud.softech.cloud/mobile/ames/api/ebm/AddSession',
      method: 'POST',
      headers: {
        Authorization: 'Basic 12C1F7EF9AC8E288FBC2177B7F54D',
        ApplicationName: 'SmartEducation',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: params,
    })
      .then((response: AxiosResponse<Response>) => {
        resolve(response.data.results);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const useAddingSessions = () => {
  const queryClient = useQueryClient();

  const { mutate } = useMutation(({ classId, date, sessionId, userId }: AddEBMSessionProps) => {
    return addEBMSessions({ classId, date, sessionId, userId });
  });

  /**
   * Giao bài trong thư viện bài tập cho học sinh theo lớp và ngày
   * @param classId - number: Mã lớp học
   * @param sessionId - string: Mã bài học
   * @param date - string: Ngày hiện tại
   * @param KEY - (string | number)[] (tùy chọn): Key của p_AMES247_EBM_Assignment_GetSession_ActiveClass
   * @param userId - string: Mã học sinh
   * @returns Thông báo thành công (và refresh data) hoặc thất bại
   */
  const handleAssignment = ({ classId, date, KEY, sessionId, userId }: useAddingSessionsProps) => {
    mutate(
      { classId, date, sessionId, userId },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(KEY);
          notification.success({
            message: 'Thông báo',
            description: 'Giao bài thành công',
          });
        },
        onError: () => {
          notification.error({
            message: 'Thông báo',
            description: 'Giao bài thất bại',
          });
        },
      },
    );
  };
  return { handleAssignment };
};

export default useAddingSessions;
