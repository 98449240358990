import * as ActionTypes from './types';

export const getMenus = () => {
  return {
    type: ActionTypes.REPORT_GET_MENUS,
  };
};

export const getDivision = () => {
  return {
    type: ActionTypes.REPORT_GET_DIVISIONS,
  };
};
