import { Button, Col, Collapse, Radio, Row, Skeleton, Space } from 'antd';
import moment from 'moment';
import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { SessionsTypeProps } from '../AssignmentSessions';
import useAddingSessions from './useAddingSessions';
import useGettingCourse from './useGettingCourse';
import useGettingCurriculumAll from './useGettingCurriculumAll';
import useGettingEBMSessions from './useGettingEBMSessions';

const { Panel } = Collapse;

type CurriculumsSessionsProps = {
  classId: number;
  queryKey: (string | number | boolean)[];
  studentId: number;
  onBack: Dispatch<SetStateAction<SessionsTypeProps>>;
};

const CurriculumsSessions = ({ classId, queryKey, studentId, onBack }: CurriculumsSessionsProps) => {
  const maxLevel = 4;
  const { dataLevel1, dataLevel2, isLoading: dataLevel1And2Loading, setDataLevel2Index } = useGettingCurriculumAll();
  const { data: dataLevel3, isLoading: dataLevel3Loading, getCurriculumId } = useGettingCourse();
  const { data: dataLevel4, isLoading: dataLevel4Loading, getCourseIdAndClassId } = useGettingEBMSessions();
  const [dataLevel, setLevel] = React.useState<number>(1);
  const [value, setValue] = useState<number>();
  const [subTitle, setSubTitle] = useState<string>('');
  const [subTitleArray, setSubTitleArray] = useState<string[]>([]);

  const { handleAssignment } = useAddingSessions();

  const extraFeatures = () => {
    return (
      <Button
        key='0'
        onClick={(event) => {
          if (dataLevel === 1) {
            onBack('assignment');
          } else {
            setLevel(dataLevel - 1);
            setSubTitle(subTitleArray[dataLevel - 3]);
            // Prevent click extra trigger collapse.
            event.stopPropagation();
          }
        }}
        size='small'
      >
        Quay lại
      </Button>
    );
  };

  const handleItemClick = (id: string | number, index: number, name: string, title: string) => {
    const subTitle = name || title;
    if (dataLevel < maxLevel) {
      setLevel(dataLevel + 1);
    }
    // Dựa vào vị trí trong menu cấp 1 để lấy menu cấp 2.
    if (dataLevel === 1) {
      setDataLevel2Index(index);
    }
    // Dựa vào id trong menu cấp 2 để lấy menu cấp 3.
    if (dataLevel === 2 && typeof id === 'number') {
      getCurriculumId(id);
    }
    // Dựa vào id trong menu cấp 3 để lấy menu cấp 4.
    if (dataLevel === 3 && typeof id === 'string') {
      getCourseIdAndClassId({ classId, courseId: id });
      setValue(0);
    }

    if (dataLevel !== 4) {
      setSubTitle(subTitle);
      const temp = subTitleArray;
      const index = temp.indexOf(subTitle);
      if (index < 0 && !temp[dataLevel - 1]) temp.push(subTitle);
      temp.forEach(function (item, i) {
        if (i === dataLevel - 1) temp[i] = subTitle;
      });
      setSubTitleArray(temp);
    }
  };

  const currentData = useCallback(
    (level: number) => {
      switch (level) {
        case 1:
          return dataLevel1;
        case 2:
          return dataLevel2();
        case 3:
          return dataLevel3;
        case 4:
          return dataLevel4;
        default:
          return null;
      }
    },
    [dataLevel1, dataLevel2, dataLevel3, dataLevel4],
  );

  if (dataLevel1And2Loading || dataLevel3Loading || dataLevel4Loading) {
    return (
      <Collapse defaultActiveKey='1'>
        <Panel header='Thư viện bài tập' key='1' extra={extraFeatures()}>
          <Skeleton active paragraph={{ rows: 2 }} />
        </Panel>
      </Collapse>
    );
  }
  const panelHeader = (subTitle: string) => {
    return (
      <React.Fragment>
        <div style={{ fontSize: 16 }}>Thư viện bài tập</div>
        <div style={{ fontSize: 12 }}>{subTitle}</div>
      </React.Fragment>
    );
  };

  return (
    <Collapse defaultActiveKey={['1']}>
      <Panel header={panelHeader(subTitle)} key='1' extra={extraFeatures()}>
        <Radio.Group onChange={(e) => setValue(e.target.value)} style={{ width: '100%' }} value={value}>
          <Space direction='vertical' size='middle' style={{ width: '100%' }}>
            {/* id: number; name: string => menu cấp 1, 2 */}
            {/* id: string; title: string => menu cấp 3, 4 */}
            {currentData(dataLevel)?.map(({ id, name, title }: any, index: number) => (
              <Row align='middle' justify='space-between' key={id} onClick={() => handleItemClick(id, index, name, title)}>
                <Col span={dataLevel === maxLevel ? 12 : 24}>
                  <Radio value={index}>{dataLevel < 3 ? name : title}</Radio>
                </Col>
                {dataLevel === maxLevel && (
                  <Col span={12} style={{ height: 32, textAlign: 'right' }}>
                    {/* {value === index && <Button type='primary'>Giao bài</Button>} */}
                    {value === index && (
                      <Button onClick={() => handleAssignment({ classId, date: moment().format('DD-MM-YYYY'), KEY: queryKey, sessionId: id, userId: '' })} type='primary'>
                        Giao bài
                      </Button>
                    )}
                  </Col>
                )}
              </Row>
            ))}
          </Space>
        </Radio.Group>
      </Panel>
    </Collapse>
  );
};

export default CurriculumsSessions;
