/* eslint-disable no-undef */

import numeral from 'numeral';
import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { message } from 'antd';
import DynamicForm from 'packages/DynamicForm/components/DynamicForm';
import DynamicTable from 'packages/DynamicForm/components/DynamicTable';
import ReportHelper from 'helpers/ReportHelper';
import { query, queryExcel } from 'helpers/QueryHelper';

const queryResults = (storedProcedure, parameters, appName) => {
  return new Promise((resolve, reject) => {
    try {
      message.loading({ content: 'Đang xử lý dữ liệu...', key: 'updatable' });
      query(storedProcedure, parameters, appName)
        .then((data) => {
          message.success({ content: `Kết quả: ${numeral(data.length).format('0,0')} dữ liệu`, key: 'updatable', duration: 3 });
          resolve(data);
        })
        .catch((error) => {
          message.error({ content: `Có lỗi xảy ra: ${error}`, key: 'updatable', duration: 3 });
          reject(error);
        });
    } catch (error) {
      message.error({ content: `Có lỗi xảy ra: ${error}`, key: 'updatable', duration: 3 });
      reject(error);
    }
  });
};

const exportToExcel = (storedProcedure, parameters, appName) => {
  return new Promise((resolve, reject) => {
    try {
      message.loading({ content: 'Đang xử lý dữ liệu...', key: 'updatable' });
      queryExcel(storedProcedure, parameters, appName)
        .then((data) => {
          message.success({ content: `Kết quả: ${numeral(data.length).format('0,0')} dữ liệu`, key: 'updatable', duration: 3 });
          resolve(data);
        })
        .catch((error) => {
          message.error({ content: `Có lỗi xảy ra: ${error}`, key: 'updatable', duration: 3 });
          reject(error);
        });
    } catch (error) {
      message.error({ content: `Có lỗi xảy ra: ${error}`, key: 'updatable', duration: 3 });
      reject(error);
    }
  });
};

export default function DynamicReport() {
  const [loading, setLoading] = React.useState(false);
  const [records, setRecords] = React.useState([]);
  const [formData, setFormData] = React.useState(null);
  // get form id from query string
  const { id } = useParams();
  const location = useLocation();

  React.useEffect(() => {
    setRecords([]);
  }, [location.pathname]);

  React.useEffect(() => {
    ReportHelper.GetForm(id).then((result) => {
      setFormData(result);
    });
  }, [id]);

  return (
    <React.Fragment>
      {/* --------------------------------------------------------------------------------------------- */}
      {/* FORM */}
      {/* --------------------------------------------------------------------------------------------- */}
      <DynamicForm
        loading={loading}
        initialFormData={formData}
        showExportToExcelButton={false}
        onSubmit={async (parameters) => {
          console.log(parameters);
          setLoading(true);
          const result = await queryResults(formData.storedProcedure, parameters, formData.appName);
          // console.log(result);
          setRecords(result);
          setLoading(false);
        }}
        onExportToExcel={async (parameters) => {
          console.log(parameters);
          setLoading(true);
          const result = await exportToExcel(formData.storedProcedure, parameters, formData.appName);
          setLoading(false);
          window.open(result.excelDownloadUrl, '_blank');
        }}
      />

      <DynamicTable loading={loading} dataSource={records} initialTableData={formData} />
    </React.Fragment>
  );
}
