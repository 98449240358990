import * as ActionTypes from '../actions/types';

const defaultState = {
  loading: false,
  menus: [],
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.REPORT_GET_MENUS: {
      return {
        ...state,
        loading: true,
        menus: [],
      };
    }

    case ActionTypes.REPORT_GET_MENUS_SUCCESS: {
      return {
        ...state,
        loading: false,
        menus: action.menus,
      };
    }

    case ActionTypes.REPORT_GET_MENUS_ERROR: {
      return {
        ...state,
        loading: false,
        menus: [],
        error: action.error,
      };
    }

    default:
      return state;
  }
};
