import { notification } from 'antd';
import { execute } from 'helpers/QueryHelper';
import moment from 'moment';
import { useMutation, useQueryClient } from 'react-query';

type useAddingSessionsProps = {
  classId: number;
  sessionId: string;
  KEY?: (string | number)[];
  studentId: number;
};

const useAddingSessions = () => {
  const queryClient = useQueryClient();

  const { mutate } = useMutation((props: useAddingSessionsProps) => {
    const { classId, sessionId, studentId } = props;
    return execute('[p_API_MYAMES_ATTANDANCE_AddSessions_QRCode_v2]', { classId, sessionId, date: moment().format('YYYY-MM-DD HH:mm:ss'), studentId }, 'SmartEducation');
  });

  /**
   * Giao bài theo lớp và ngày
   * @param classId - number: Mã lớp học
   * @param sessionId - string: Mã bài học
   * @param KEY - (string | number)[] (tùy chọn): Key của p_API_MYAMES_ATTANDANCE_GetSessions_QRCode_v2
   * @param studentId - number: Mã học sinh
   * @returns Thông báo thành công (và refresh data) hoặc thất bại
   */
  const handleAssignment = (props: useAddingSessionsProps) => {
    const { classId, sessionId, KEY, studentId } = props;
    mutate(
      { classId, sessionId, studentId },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(KEY);
          notification.success({
            message: 'Thông báo',
            description: 'Giao bài thành công',
          });
        },
        onError: () => {
          notification.error({
            message: 'Thông báo',
            description: 'Giao bài thất bại',
          });
        },
      },
    );
  };

  return {
    handleAssignment,
  };
};

export default useAddingSessions;
