export default {
  storedProcedure: 'STORED NAME HERE',
  formName: 'Tìm theo lớp',
  appName: 'EBM',
  requiredMark: false,
  submitButtonText: 'Tìm kiếm',
  formFields: [
    {
      label: 'Class',
      name: 'searchValue',
      initialValue: '',
      hasFeedback: true,
      rules: [
        {
          required: true,
          message: 'Chưa nhập thông tin lớp',
        },
      ],
      inputComponent: {
        type: 'Input',
        props: {},
      },
    },
  ],
};
